* + .pratical-services {
  padding-top: 90px;
  background: linear-gradient(180deg, #1a3d68 0%, rgba(26, 61, 104, 0) 100%);
}

@media (--tablet) {
  * + .pratical-services {
    padding-top: 70px;
  }
}

@media (--mobile) {
  * + .pratical-services {
    padding-top: 35px;
  }
}

.pratical-services__container {
  display: flex;

  overflow: hidden;
  letter-spacing: 0.01em;
  height: 100%;
}

@media (--mobile) {
  .pratical-services__container {
    flex-direction: column;
  }
}

.pratical-services__menu {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(12px);
  padding: 32px 24px;
  border-radius: 8px;

  height: 100%;

  display: block;
}

@media (--tablet) {
  .pratical-services__menu {
    padding: 26px 20px;
  }
}

@media (--mobile) {
  .pratical-services__menu {
    padding: 16px 16px;
  }
}

.pratical-services__item {
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-gap: 16px;
  justify-content: center;
  padding: 13px 24px;
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;

  transition: background 0.3s ease-in-out;
}

.pratical-services__item__link {
  transition: background 0.3s ease-in-out;
}

.pratical-services__item__link:hover {
  background: rgba(255, 255, 255, 0.04);
  border-radius: 8px;
}

.pratical-services__item__link + .pratical-services__item__link {
  margin-top: 16px;
}

@media (--mobile) {
  .pratical-services__item {
    grid-gap: 12px;
    padding: 12px 8px 8px 8px;
  }

  .pratical-services__item__link + .pratical-services__item__link {
    margin-top: 5px;
  }
}

.pratical-services__item--current {
  background: rgba(255, 255, 255, 0.1);
}

.pratical-services__container h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.3;
}

.pratical-services__content__text__item__title + .text {
  padding-top: 16px;
}

.pratical-services__content__container {
  padding-left: 49px;
}
@media (--tablet) {
  .pratical-services__content__container {
    padding-left: 26px;
  }
}

@media (--mobile) {
  .pratical-services__content__container {
    padding-top: 26px;
    padding-left: 0;
  }

  .pratical-services__container h3 {
    font-size: 15px;
  }
}

.pratical-services__content__text__item p img {
  width: 100%;
  overflow: hidden;

  border-radius: 5px;
}

.pratical-services__icon {
  --size: 25px;

  justify-self: center;

  width: var(--size);
  height: var(--size);
}

@media (--mobile) {
  .pratical-services__icon {
    --size: 16px;
  }
}

.pratical-services__icon svg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pratical-services__content__text__item {
  padding-bottom: 12px;
  display: none;
}

/*@media (--mobile) {
  .pratical-services__content__scroll {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.pratical-services__content__wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: flex-end;
  width: 1000px;
}
@media (--mobile) {
  .pratical-services__content__wrap {
    position: static;

    display: flex;
    overflow-x: auto;
    width: 100%;
    padding-left: 210px;
    -webkit-overflow-scrolling: touch;
  }
}

.pratical-services__content__wrap::-webkit-scrollbar {
  display: none;
}

@media (--mobile) {
  .pratical-services__content__wrap::after {
    content: '\A0';
    display: block;
    width: 0;
    margin-left: var(--page-left-padding);
  }
}*/

.pratical-services__content__text__item--current {
  display: block;
}

.pratical-services__content__text__item a {
  color: var(--orange-500);
  text-decoration: none;
}

.pratical-services__content__text__item a:hover {
  text-decoration: underline;
}

.pratical-services__content__text__item > h3,
.pratical-services__content__text__item > p {
  line-height: 1.35;
  color: #ffffff;
}

.pratical-services__content__text__item > h3 {
  font-weight: 700;
  font-size: 16px;
}

.pratical-services__content__text__item > p,
ul {
  font-weight: 400;
  font-size: 14px;
}

.pratical-services__content__text__item > h3 + p {
  padding-top: 12px;
}

.pratical-services__content__text__item > p + h3 {
  padding-top: 30px;
}

.pratical-services__content__text__item > p + p {
  padding-top: 24px;
}

.pratical-services__content__text__item > p + ul {
  padding-top: 8px;
}

.pratical-services__content__text__item > ul > li + li {
  padding-top: 4px;
}
.pratical-services__content__text__item > ul > li {
  margin-left: 20px;
}

.pratical-services__content__text__item__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.3;
  color: #ffffff;
  text-transform: uppercase;
}

.pratical-services__content__text__item__title + p {
  padding-top: 20px;
}

.pratical-services__content__text__item__title + h3 {
  padding-top: 20px;
}
