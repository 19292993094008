.cta {
  text-align: center;
  max-width: 529px;
  margin: auto;
}

* + .cta {
  margin-top: 88px;
}

@media (max-width: 425px) {
  .cta__title {
    font-size: 14px;
  }
}

.cta__description {
  margin-top: 24px;
  line-height: var(--p-line-height);
}

.cta__button {
  margin: 24px auto;
}
