* + .last-events {
  margin-top: 48px;
}

@media (--mobile) {
  * + .last-events {
    margin-top: 30px;
  }
}

.last-events {
  --animation-duration: 0.3s;
}

.last-events__slider {
  width: 660px;
  margin: auto;
  position: relative;
  height: 100px;
  overflow: hidden;
}

.last-events__slider__list {
  --width: 94px;

  color: black;
  display: flex;
  list-style: none;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  transition: transform var(--animation-duration) ease-in-out;
}

.last-events__slider__list__date {
  text-align: center;
  position: relative;
  width: var(--width);
  color: var(--primary-500);
}

.last-events--light .last-events__slider__list__date {
  color: var(--white);
}

.last-events__slider__list__date__num {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  transition: all var(--animation-duration) ease-in-out;
}

.last-events__slider__list__date--current {
  margin-top: 8px;
}

.last-events__slider__list__date:not(
    .last-events__slider__list__date--current
  ) {
  padding-top: 8px;
}

.last-events__slider__list__date--current
  .last-events__slider__list__date__num {
  transform: scale(1.4);
  color: var(--orange-500);
}

.last-events__slider__list__date__weekday,
.last-events__slider__list__date__month {
  opacity: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  transition: all var(--animation-duration) ease-in-out !important;
}

.last-events__slider__list__date__weekday {
  transform: translateY(-5px);
}

.last-events__slider__list__date__month {
  transform: translateY(5px);
}

.last-events__slider__list__date--current
  .last-events__slider__list__date__weekday {
  transform: translateY(-10px);
}

.last-events__slider__list__date--current
  .last-events__slider__list__date__month {
  transform: translateY(10px);
}

.last-events__slider__list__date:not(.last-events__slider__list__date--current)
  .last-events__slider__list__date__weekday,
.last-events__slider__list__date:not(.last-events__slider__list__date--current)
  .last-events__slider__list__date__month {
  transition: opacity var(--animation-duration) ease-in-out;
}

.last-events__slider__list__date__weekday
  + .last-events__slider__list__date__num,
.last-events__slider__list__date__num
  + .last-events__slider__list__date__month {
  margin-top: 0;
}

.last-events__slider__list__date--current
  .last-events__slider__list__date__weekday,
.last-events__slider__list__date--current
  .last-events__slider__list__date__month {
  opacity: 1;
}

@media (pointer: fine) {
  .last-events__slider__list__date:not(
      .last-events__slider__list__date--current
    ):not(.last-events__slider__list__date--passed):hover
    .last-events__slider__list__date__weekday,
  .last-events__slider__list__date:not(
      .last-events__slider__list__date--current
    ):not(.last-events__slider__list__date--passed):hover
    .last-events__slider__list__date__month {
    opacity: 0.5;
  }
}

.last-events__slider__list__date--passed:hover {
  cursor: initial;
}

.last-events__slider__list__date--passed {
  opacity: 0.5;
}

.last-events__slider__list__date__ellipse {
  position: absolute;
  bottom: 10px;
  left: 47.5%;
  opacity: 0;
  transition: opacity var(--animation-duration) ease-in-out;
}

.last-events__slider__list__date--has-event:not(
    .last-events__slider__list__date--current
  )
  .last-events__slider__list__date__ellipse {
  opacity: 1;
}

.last-events__slider__list__date:not(
    .last-events__slider__list__date--current
  ):hover
  .last-events__slider__list__date__ellipse {
  opacity: 0;
}

.last-events__slider + .last-events__sections {
  margin-top: 23px;
}

.last-events__sections {
  display: grid;
  grid-template-columns: 420px 1fr;
  gap: 16px;
  min-height: 230px;
}

@media (--tablet) {
  .last-events__sections {
    grid-template-columns: repeat(2, 1fr);
  }
}

.last-events__sections__current {
  background-color: var(--primary-500);
  padding: 60px 42px;
  border-radius: 8px;
}

@media (--tablet) {
  .last-events__sections__current {
    padding: 60px 25px;
  }
}

.last-events--light .last-events__sections__current {
  background-color: var(--white);
}

.last-events__sections__current__sup-title {
  text-align: center;
  color: white;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
}

.last-events--light .last-events__sections__current__sup-title {
  color: var(--primary-500);
}

.last-events__sections__current__title {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  text-align: center;
  color: var(--orange-500);
}

.last-events__sections__current__sup-title
  + .last-events__sections__current__title {
  margin-top: 6px;
}

.last-events__sections__current__list {
  list-style: none;
  width: 80%;
  margin: auto;
}

@media (--tablet) {
  .last-events__sections__current__list {
    width: 100%;
  }
}

.last-events--light .last-events__sections__current__list {
  color: var(--primary-500);
}

.last-events__sections__current__title + .last-events__sections__current__list {
  margin-top: 23px;
}

.last-events__sections__current__list__event,
.last-events__sections__current__list > p {
  border-left: 1px solid white;
  padding-left: 12px;
}

.last-events--light .last-events__sections__next__section__list__event,
.last-events--light .last-events__sections__next__section__list > p {
  border-left: 1px solid var(--white);
  padding-left: 12px;
}

* + .last-events__sections__current__list__event,
* + .last-events__sections__next__section__list__event {
  margin-top: 24px;
}

.last-events__sections__current__list__event > * + ul,
.last-events__sections__next__section__list__event > * + ul {
  margin-top: 10px;
}

.last-events__sections__current__list__event li:not(:first-child),
.last-events__sections__next__section__list__event li:not(:first-child) {
  margin-top: 8px;
}

.last-events__sections__current__list__event ul,
.last-events__sections__next__section__list__event ul {
  margin-left: 20px;
}

.last-events__sections__current__list__event a,
.last-events__sections__next__section__list__event a {
  color: var(--orange-500);
  text-decoration: none;
}

.last-events__sections__current__list__event a:hover,
.last-events__sections__next__section__list__event a:hover {
  text-decoration: underline;
}

.last-events__sections__current__list__event > div,
.last-events__sections__next__section__list__event > div {
  position: relative;

  width: 100%;
  height: 0;
  padding-bottom: 40%;
}

.last-events__sections__current__list__event img,
.last-events__sections__next__section__list__event img {
  width: 100%;

  max-height: 200px;
  height: auto;
  object-fit: contain;
  object-position: left center;
}

.last-events__sections__current__list__event img + *,
.last-events__sections__next__section__list__event img + * {
  margin-top: 16px;
}

.last-events__sections__current__list__event pre,
.last-events__sections__next__section__list__event pre {
  width: 100%;

  font-weight: 700;
  font-family: var(--font-family);
  white-space: normal;
  line-height: 140%;
  letter-spacing: 0.01em;
  font-style: italic;
}

.last-events__sections__current__list__event > * + pre,
.last-events__sections__next__section__list__event > * + pre {
  margin-top: 10px;
}

.last-events__sections__current__list__event > pre::before,
.last-events__sections__next__section__list__event > pre::before {
  content: "“";
}

.last-events__sections__current__list__event > pre::after,
.last-events__sections__next__section__list__event > pre::after {
  content: "”";
}

.last-events__sections__next {
  border: 1px solid var(--primary-500);
  border-radius: 8px;
  padding: 33px 59px;
}

@media (--tablet) {
  .last-events__sections__next {
    padding: 33px 25px;
  }
}

.last-events--light .last-events__sections__next {
  background-color: #0c2441;
}

.last-events__sections__next__title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: var(--primary-500);
  text-align: center;
}

.last-events--light .last-events__sections__next__title {
  color: var(--white);
}

.last-events__sections__next__sections {
  display: grid;
  gap: 63px;
  grid-template-columns: repeat(2, 1fr);
}

.last-events__sections__next__title + .last-events__sections__next__sections {
  margin-top: 37px;
}

.last-events__sections__next__section {
  color: var(--primary-500);
}

.last-events--light .last-events__sections__next__section {
  color: var(--white);
}

.last-events__sections__next__section__date {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0.01em;
}

.last-events__sections__next__section__date
  + .last-events__sections__next__section__list {
  margin-top: 10px;
}

.last-events__sections__next__section__list li:not(:first-of-type) {
  margin-top: 12px;
}

.last-events__sections__next__section__list li {
  margin-left: 7px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
}

@media (max-width: 650px) {
  .last-events .max-width {
    max-width: unset;
    padding: 0;
  }

  .last-events__slider {
    width: 100%;
    overflow: auto;
    scroll-snap-type: x mandatory;
  }

  .last-events__slider li {
    scroll-snap-align: center;
  }

  .last-events__sections {
    display: block;
    min-height: unset;
    width: 90%;
    margin: auto;
  }

  .last-events__sections__current + .last-events__sections__next {
    margin-top: 20px;
  }
}

@media (--mobile) {
  .last-events__sections__current,
  .last-events__sections__next {
    padding: 30px 20px;
  }

  .last-events__sections__next__sections {
    display: block;
  }

  .last-events__sections__next__section:not(:first-of-type) {
    margin-top: 20px;
  }

  .last-events__sections__current__list {
    width: 100%;
  }

  .last-events__sections__next__title + .last-events__sections__next__sections {
    margin-top: 20px;
  }
}
