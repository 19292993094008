.button {
  --height: 50px;
  --min-height: var(--height);
  --horizontal-padding: 16px;
  --vertical-padding: 0;
  --color: var(--primary-600);
  --background-color: var(--white);

  position: relative;

  display: inline-block;
  height: var(--height);
  min-height: var(--min-height);
  padding-right: var(--horizontal-padding);
  padding-left: var(--horizontal-padding);
  overflow: hidden;

  text-decoration: none;

  outline: none;
  cursor: pointer;
  border-radius: 999px;
  user-select: none;
  transition-duration: 450ms;
  transition-property: color;
}
@media (--mobile) {
  .button {
    --height: 46px;
  }
}

.button--disabled {
  --background-color: lightgrey !important;

  cursor: default;
}

/** Heights **/

.button--height-small {
  --height: 44px;
}

.button--height-extra-small {
  --height: auto;
  --min-height: 32px;
  --vertical-padding: 4px;
}

/** Horizontal paddings **/

.button--horizontal-padding-large {
  --horizontal-padding: 24px;
}

/** Themes **/

.button--theme-white {
  --color: var(--primary-500);
  --background-color: var(--white);
}

.button--theme-primary-white {
  --color: var(--primary-500);
  --background-color: var(--red);
  border: 1px solid black;
}

.button--theme-secondary {
  --color: var(--primary-600);
  --background-color: var(--secondary-500);
}

.button--theme-secondary-outline {
  --color: var(--secondary-500);
  --background-color: none;
  --horizontal-padding: 44px;
}

.button--theme-transparent {
  --color: var(--white);
  --background-color: rgba(255, 255, 255, 0.1);
}

.button--theme-light {
  --height: auto;
  --color: var(--orange-500);
  --background-color: none;
  --horizontal-padding: 0;

  border-radius: 0;
}
.button--disabled.button--theme-light {
  --background-color: none !important;
}

.button--theme-light-blue {
  --height: auto;
  --color: var(--primary-500);
  --background-color: none;
  --horizontal-padding: 0;

  border-radius: 0;
}

.button--theme-light-white {
  --height: auto;
  --color: white;
  --background-color: none;
  --horizontal-padding: 0;

  border-radius: 0;
}

.button--theme-outline {
  --color: var(--white);
  --background-color: rgba(255, 255, 255, 0.04);
}

.button--theme-orange {
  --color: var(--primary-500);
  --background-color: var(--orange-500);
}

.button--theme-blue {
  --height: auto;

  --color: var(--white);
  --background-color: var(--primary-500);

  padding-top: 7px;
  padding-bottom: 7px;
}

.button.button--theme-outline-orange {
  --horizontal-padding: 37px;
  --color: var(--orange-500);
  --background-color: rgba(255, 255, 255, 0.2);
}
@media (--mobile) {
  .button.button--theme-outline-orange {
    --horizontal-padding: 27px;
  }
}

/** Hover state **/

.button:not(.button--disabled):hover {
  --color: var(--secondary-800);
  --background-color: var(--secondary-400);
}

.button--theme-primary-white:not(.button--disabled):hover {
  --color: var(--primary-600);
  --background-color: rgba(240, 245, 255, 1);
}

.button--theme-secondary:not(.button--disabled):hover {
  --color: var(--primary-600);
  --background-color: var(--secondary-400);
}

.button--theme-secondary-outline:not(.button--disabled):hover {
  --color: var(--secondary-400);
  --background-color: rgba(216, 189, 115, 0.1);
}

.button--theme-outline:not(.button--disabled):hover {
  --color: var(--white);
  --background-color: rgba(255, 255, 255, 0.1);
}

.button.button--theme-outline-orange:not(.button--disabled):hover {
  --color: var(--orange-500);
  --background-color: rgba(223, 162, 68, 0.1);
}

.button.button--theme-blue:not(.button--disabled):hover {
  --color: var(--white);
  --background-color: rgb(20, 53, 92);
}

.button.button--theme-transparent:not(.button--disabled):hover {
  --color: var(--white);
  --background-color: rgba(255, 255, 255, 0.3);
}

.button--theme-light:not(.button--disabled):hover {
  --color: var(--secondary-500);
  --background-color: none;
}

.button--theme-light-blue:not(.button--disabled):hover {
  --color: rgb(20, 53, 92);
  --background-color: none;
}

.button--theme-light-white:not(.button--disabled):hover {
  --color: white;
  --background-color: none;
}

/** Active state **/

.button:not(.button--disabled):active {
  --color: var(--secondary-800);
  --background-color: var(--secondary-500);
}

.button--theme-outline:not(.button--disabled):active {
  --color: var(--white);
  --background-color: rgba(255, 255, 255, 0.3);
}

.button.button--theme-outline-orange:not(.button--disabled):active {
  --color: var(--orange-500);
  --background-color: rgba(223, 162, 68, 0.3);
}

.button--theme-primary-white:not(.button--disabled):active {
  --color: var(--primary-600);
  --background-color: rgba(229, 235, 247, 1);
}

.button.button--theme-blue:not(.button--disabled):active {
  --color: var(--white);
  --background-color: var(--primary-500);
}

.button.button--theme-transparent:not(.button--disabled):active {
  --color: var(--white);
  --background-color: rgba(255, 255, 255, 0.1);
}

.button--theme-secondary-outline:not(.button--disabled):active {
  --color: var(--secondary-500);
  --background-color: none;
}

.button--theme-light:not(.button--disabled):active {
  --color: var(--secondary-500);
  --background-color: none;
}

.button--theme-light-blue:not(.button--disabled):active {
  --color: var(--primary-500);
  --background-color: none;
}

.button--theme-light-white:not(.button--disabled):active {
  --color: var(--white);
  --background-color: none;
}

.button--theme-secondary:not(.button--disabled):active {
  --color: var(--primary-600);
  --background-color: var(--secondary-500);
}

/** Background **/

.button__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  border-radius: 999px;
  background-color: var(--background-color);
  transition-duration: 200ms;
  transition-property: opacity, background-color;
}

.button--theme-outline .button__background {
  backdrop-filter: blur(12px);
  border: 2px solid var(--white);
}

.button--theme-secondary-outline .button__background {
  backdrop-filter: blur(12px);
  border: 2px solid var(--secondary-500);
}

.button--theme-outline-orange .button__background {
  border: 2px solid var(--orange-500);
}

/** Content **/

.button__content {
  display: inline-flex;
  align-items: center;
  height: var(--height);
  min-height: var(--min-height);
}

.button__left-icon {
  position: relative;
  display: flex;
  align-items: center;
}
.button__left-icon svg {
  --size: 16px;

  width: var(--size);
  height: var(--size);
  object-fit: contain;
}

.button__text {
  z-index: 1;
  position: relative;

  flex: 1;
  display: flex;
  align-items: center;
  padding-top: var(--vertical-padding);
  padding-bottom: var(--vertical-padding);

  color: var(--color);
  text-decoration: none;
  font-family: var(--font-family-heading);
  font-weight: 700;
  font-size: 16px;
  line-height: 1.1;
  letter-spacing: 0.01em;
  text-align: center;
}

@media (--mobile) {
  .button__text {
    font-size: 14px;
  }
}

.button.button--theme-light .button__text {
  font-family: var(--font-family);
  font-weight: normal;
}

.button.button--theme-light-blue .button__text {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
}

.button.button--theme-light-white .button__text {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
}

.button.button--theme-blue .button__text {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
}

.button.button--theme-transparent .button__text {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
}

* + .button__text {
  margin-left: 10px;
}

.button__right-icon {
  position: relative;

  display: flex;
  align-items: center;
  height: 20px;
  padding-left: 12px;
}

.button--theme-simple .button__right-icon {
  padding-left: 0;
}

* + .button__right-icon {
  margin-left: 8px;
}

.button--theme-blue .button__right-icon svg * {
  fill: var(--white);
}

.button--theme-simple .button__right-icon svg * {
  fill: var(--color);
}

.button--theme-blue .button__left-icon svg * {
  fill: var(--white);
}

.button--theme-light:hover .button__right-icon svg * {
  fill: var(--secondary-500);
}

.button--theme-light-blue .button__right-icon svg * {
  fill: var(--primary-500);
}

.button--theme-light-white .button__right-icon svg * {
  fill: white;
}

.button--theme-light-blue:hover .button__right-icon svg * {
  fill: rgb(20, 53, 92);
}

.button--theme-light-white:hover .button__right-icon svg * {
  fill: white;
}

.button--theme-secondary-outline .button__right-icon svg * {
  fill: var(--secondary-500);
}
.button--theme-outline .button__right-icon svg * {
  fill: var(--white);
}

.button--theme-light * + .button__right-icon {
  margin-left: 0;
}

.button--theme-light-blue * + .button__right-icon {
  margin-left: 0;
}

.button--theme-light-white * + .button__right-icon {
  margin-left: 0;
}

.button:not(.button--theme-light) .button__right-icon::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  content: '';

  border-left: 1px solid var(--color);
  opacity: 0.4;
}

.button--theme-primary-white .button__content {
  width: 100%;
}

.button--theme-primary-white .button__right-icon {
  float: right;
}

.button--theme-light-blue .button__right-icon::before {
  display: none;
}

.button--theme-simple .button__right-icon::before {
  display: none;
}

.button--theme-light-white .button__right-icon::before {
  display: none;
}

.button__right-icon svg {
  --size: 14px;

  width: var(--size);
  height: var(--size);
  object-fit: contain;
}
