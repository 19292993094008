@media (--tablet) {
  .echo-press__top {
    text-align: center;
  }
}

* + .echo-press {
  margin-top: 90px;
  letter-spacing: 0.01em;
}
@media (--tablet) {
  * + .echo-press {
    margin-top: 70px;
  }
}

.echo-press__top__sup-title {
  font-family: var(--font-family-heading);
  text-transform: uppercase;
  color: var(--orange-500);
  font-weight: 600;
  font-size: 16px;
  line-height: var(--p-line-height);
}

.echo-press__top__title {
  font-family: var(--font-family-heading);
  text-transform: uppercase;
  color: var(--white);
  font-weight: 700;
  font-size: 40px;
}
@media (--tablet) {
  .echo-press__top__title {
    font-size: 28px;
  }
}

* + .echo-press__top__title {
  margin-top: 4px;
}

.echo-press__bottom {
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 60px;
}
@media (--tablet) {
  .echo-press__bottom {
    grid-template-columns: 1fr;
    row-gap: 40px;
  }
}

* + .echo-press__bottom {
  margin-top: 40px;
}

.echo-press__list__priority,
.echo-press__list {
  list-style: none;
  display: flex;
  flex-direction: column;
}

@media (--tablet) {
  .echo-press__list__priority {
    grid-row-start: 1;
    text-align: center;
  }
}

.echo-press__list__item {
  position: relative;

  display: flex;
  align-items: flex-end;
  padding-bottom: 24px;
}
@media (--tablet) {
  .echo-press__list__item {
    flex-direction: column;
  }
}

* + .echo-press__list__priority__item {
  margin-top: 24px;
}

.echo-press__list__item__sup-title {
  text-transform: uppercase;

  color: var(--orange-500);
  font-size: 14px;
  line-height: 1.4;
}

.echo-press__list__item__icon {
  --size: 60px;

  flex-shrink: 0;
  width: var(--size);
  height: var(--size);
  overflow: hidden;
  align-self: baseline;
  margin-right: 12px;
  border-radius: 12px;
}
@media (--tablet) {
  .echo-press__list__item__icon {
    --size: 55px;
  }
}

.echo-press__list__item__icon img {
  width: 100%;
  height: 100%;

  object-fit: contain;
}

.echo-press__list__item__container {
  flex: 1;
}

@media (--tablet) {
  .echo-press__list__item__container {
    align-self: baseline;
  }
}

@media (--tablet) {
  .echo-press__list__item__icon + .echo-press__list__item__container {
    margin-top: 11px;
  }
}

* + .echo-press__list__item__title {
  margin-top: 8px;
}

* + .echo-press__list__item__content {
  margin-top: 4px;
}

.echo-press__list__priority__item {
  position: relative;
}

.echo-press__list__priority .echo-press__list__priority__item__sup-title {
  padding-top: 20px;
  color: var(--white);
  font-size: 16px;
  line-height: 1.4;
}

.echo-press__list__item + .echo-press__list__item {
  padding-top: 24px;

  border-top: 0.5px solid rgba(255, 255, 255, 0.5);
}

.echo-press__list__item > .echo-press__list__item__container {
  flex: 1;
}

@media (--mobile) {
  .echo-press__list__item > .echo-press__list__item__container {
    display: block;
  }
}

.echo-press__list__priority__item__image {
  width: 368px;
  height: 141px;
  margin: auto;
}

@media (max-width: 420px) {
  .echo-press__list__priority__item__image {
    width: 100%;
    height: auto;
  }
}

.echo-press__list__priority__item__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  overflow: hidden;
}

.echo-press__list__item__title a {
  z-index: 1;
  color: var(--white);
  text-decoration: none;
}

.echo-press__list__item__title a::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;

  content: '';
}

.echo-press__list__priority__item__sup-title a {
  text-decoration: none;
  color: var(--white);
}

.echo-press__list__priority__item__sup-title a::after {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;

  content: '';
}

.echo-press__list__item__title {
  line-height: var(--p-line-height);
  font-weight: 700;
  font-size: 20px;
  color: var(--white);
}
.echo-press__list__item__title .echo-press__list__item__link {
  line-height: var(--p-line-height);
  font-weight: 700;
  font-size: 20px;
  color: var(--white);
}

@media (--tablet) {
  .echo-press__list__item__container .echo-press__list__item__title {
    font-size: 18px;
  }
}

.echo-press__list__item__content p {
  line-height: var(--p-line-height);
  font-weight: 400;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
}

.echo-press__list__priority__item__sup-title
  + .echo-press__list__priority__item__content {
  padding-top: 16px;
}

.echo-press__list__priority .echo-press__list__priority__item__content p {
  line-height: var(--p-line-height);
  font-weight: 600;
  font-size: 20px;
  color: var(--white);
}

@media (--tablet) {
  .echo-press__list__priority .echo-press__list__priority__item__content p {
    font-size: 18px;
  }
}

.echo-press__list__item__button {
  align-self: flex-start;
}
@media (--tablet) {
  .echo-press__list__item__button {
    margin-top: 11px;
  }
}

* + .echo-press__list__item__button {
  margin-left: 12px;
}
@media (--tablet) {
  * + .echo-press__list__item__button {
    margin-top: 12px;
    margin-left: 0;
  }
}

.echo-press__list__item:hover
  .echo-press__list__item__button
  .button
  .button__text {
  text-decoration: underline;
}

.echo-press__list__priority .echo-press__list__priority__item__button {
  margin-top: 20px;
}

.echo-press__list__priority__item:hover
  .echo-press__list__priority__item__button
  .button
  .button__text {
  text-decoration: underline;
}
