.text-page {
  position: relative;
  right: 50%;
  left: 50%;

  width: 100vw;
  margin-right: -50vw;
  margin-left: -50vw;
  padding-top: 139px;
}

.text-page__image {
  display: block;
  width: 1136px;
  max-width: 100%;
  height: 343px;
  margin-right: auto;
  margin-left: auto;
}
@media (max-width: 900px) {
  .text-page__image {
    height: 250px;
  }
}
@media (--mobile) {
  .text-page__image {
    height: 150px;
  }
}

.text-page__image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.text-page > .max-width {
  --max-width: 752px;

  margin-top: -172px;
  padding: 40px;

  background-color: var(--white);
  border-radius: 8px;
}
@media (max-width: 1004px) {
  .text-page > .max-width {
    --max-width: 100%;
  }
}
@media (max-width: 900px) {
  .text-page > .max-width {
    margin-top: 40px;
    padding-top: 0;
    padding-right: 60px;
    padding-left: 60px;

    background-color: transparent;
  }
}
@media (--tablet) {
  .text-page > .max-width {
    margin-top: 32px;
    padding-right: 32px;
    padding-left: 32px;
  }
}
@media (--mobile) {
  .text-page > .max-width {
    margin-top: 24px;
    padding-right: 24px;
    padding-left: 24px;
  }
}

.text-page__content {
  padding-right: 40px;
  color: var(--primary-500);
}
@media (max-width: 900px) {
  .text-page__content {
    padding-right: 20px;
  }
}
@media (--mobile) {
  .text-page__content {
    padding-right: 0;
  }
}

.text-page__content > ul,
.text-page__content > p {
  max-width: 71ch;

  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: 0.01em;
}
.text-page__content > ul + p,
.text-page__content > h2 + p,
.text-page__content > p + p {
  margin-top: 22px;
}

.text-page__content--sup-title {
  color: var(--orange-500);
  text-transform: uppercase;
}

.text-page__content > .text-page__content--title {
  max-width: 71ch;

  font-weight: 700;
  font-size: 16px;
  line-height: 1.3;
  letter-spacing: 0.01em;
}

.text-page__content > ul {
  margin-top: 16px;
  padding-left: 32px;
}
.text-page__content > ul > li + li {
  margin-top: 12px;
}

.text-page__content a {
  color: var(--gold-500);
  text-decoration: none;
}

.text-page__content a:hover {
  text-decoration: underline;
}

.text-page__content .block-img {
  text-align: center;
}

.text-page__content img {
  max-width: 100%;
  max-height: 500px;
  margin: auto;
  object-fit: contain;
}
