.image-text {
  --margin-top: 0;
  --js-scroll-offset-top: var(--margin-top);

  position: relative;
  right: 50%;
  left: 50%;

  width: 100vw;
  margin-right: -50vw;
  margin-bottom: 0;
  margin-left: -50vw;
  margin-top: var(--margin-top);
}

.image-text--background-light-blue-to-transparent {
  background: linear-gradient(
    180deg,
    rgba(41, 72, 110, 1) 0%,
    rgba(12, 36, 65, 1) 100%
  );
}

.image-text--background-light-blue-to-transparent,
.image-text-banner {
  padding-top: var(--margin-top);
}

.image-text__gradient {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 85%;
  background: linear-gradient(
    180deg,
    rgba(41, 72, 110, 1) 15%,
    rgba(12, 36, 65, 1) 72%,
    rgba(12, 36, 65, 0) 100%
  );
  z-index: 1;
}

.image-text {
  --margin-top: 130px;
}
* + .image-text {
  --margin-top: 100px;
}

.image-text--first {
  --margin-top: calc(var(--header-height) + 30px);
}

.js-can-use-transitions .image-text--first {
  transition-duration: 400ms;
  transition-property: all;
}

.main--flash-info .image-text--first {
  --margin-top: calc(var(--header-height) + var(--height-flash-info) + 30px);
}

@media (--tablet) {
  * + .image-text {
    --margin-top: 90px;
  }
  .image-text--first {
    --margin-top: calc(var(--header-height) + 30px);
  }
  .main--flash-info .image-text--first {
    --margin-top: calc(var(--header-height) + var(--height-flash-info) + 30px);
  }
}

@media (--mobile) {
  * + .image-text {
    --margin-top: 40px;
  }

  .image-text--first {
    --margin-top: var(--header-height);
  }
  .main--flash-info .image-text--first {
    --margin-top: calc(var(--header-height) + var(--height-flash-info-mobile));
  }
}

.image-text__container {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 60px;
  width: 100%;
  height: 100%;
  letter-spacing: 0.01em;
  z-index: 2;
}

.image-text-banner .image-text__container {
  padding-bottom: 300px;
}

.image-text__paragraph > p > a {
  color: var(--orange-500);
  text-decoration: none;
}
.image-text__paragraph > p > a:hover {
  text-decoration: underline;
}

@media (--tablet) {
  .image-text__container {
    grid-gap: 22px;
  }
}

@media (--mobile) {
  .image-text__container {
    grid-template-columns: 1fr;
  }
}

.page--background-white-carpet .image-text__title h1,
.page--background-white-carpet .image-text__title h2 {
  color: var(--primary-500);
}

.page--background-white-carpet .image-text__paragraph p {
  color: var(--primary-500);
}

.page--background-white-carpet .image-text__numbers {
  color: var(--primary-500);
}

.image-text__sup-title {
  color: var(--orange-500);
  font-family: var(--font-family-heading);
  font-weight: 600;
  text-transform: uppercase;
  line-height: var(--title-line-height);
}

.image-text__sup-title::before {
  content: '';
  display: block;
  width: 58px;
  height: 3px;
  background-color: var(--white);
  top: 0;
  margin-bottom: 16px;
}

.page--background-white-carpet .image-text__sup-title::before {
  background-color: var(--orange-500);
}

.image-text__content h1,
.image-text__content h2 {
  font-size: 32px;
  font-family: var(--font-family-heading);
  font-weight: 700;
  margin-top: 11px;
}

@media (--tablet) {
  .image-text__content h1,
  .image-text__content h2 {
    font-size: 32px;
  }
}

@media (--mobile) {
  .image-text__content h1,
  .image-text__content h2 {
    font-size: 26px;
  }
}

.image-text__paragraph {
  margin-top: 11px;
}

.image-text__paragraph p + p {
  padding-top: 11px;
}
.image-text__paragraph p + h3 {
  padding-top: 11px;
}

.image-text__paragraph h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.3;
  color: var(--white);
}

.image-text__paragraph p {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
  color: rgba(255, 255, 255, 0.8);
}

.image-text__paragraph h3 + p {
  padding-top: 11px;
}

.image-text__content button {
  margin-top: 24px;
}

.image-text__content button {
  width: 124px;
}

.image-text__content button.button .button__text {
  font-size: 14px;
}

.image-text__content button.button--right-arrow .button__right-arrow {
  width: 14px;
}

@media (--tablet) {
  .image-text__content button {
    margin-top: 20px;
  }
}

.image-text--reverse .image-text__image {
  grid-row: 1;
  grid-column: 2;
}

@media (--mobile) {
  .image-text--reverse .image-text__image {
    grid-row: auto;
    grid-column: auto;
  }
}

.image-text__image--gallery,
.image-text__image--double {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
}

.image-text__image--double-vertical {
  display: flex;
  flex-direction: column;
}

@media (--tablet) {
  .image-text__image--gallery,
  .image-text__image--double {
    grid-gap: 12px;
  }
}

@media (--mobile) {
  .image-text__image--gallery,
  .image-text__image--double {
    grid-gap: 8px;
  }

  .image-text__image--gallery .image-text__item:last-of-type {
    display: none;
  }
}

.image-text__image--gallery > .image-text__item:first-of-type {
  max-width: 255px;

  grid-column: 1 / 2;
  grid-row: 1 / 3;
  border-radius: 6px;
}
.image-text__image--double > .image-text__image {
  max-width: 255px;
  height: 418px;
  margin: auto;
}
@media (--mobile) {
  .image-text__image--gallery > .image-text__item:first-of-type {
    max-width: 100%;
    height: 100%;
  }

  .image-text__image--double > .image-text__item {
    max-width: 100%;
    height: 300px;
    margin: auto;
  }

  .image-text__image > .image-text__item {
    height: 200px !important;
  }

  .image-text__image--gallery > .image-text__item:first-of-type {
    grid-column: unset;
  }
}

.image-text--reverse .image-text__item:first-of-type {
  grid-column: 2 / 3;
  grid-row: 1 / 3;
}

.image-text__item {
  position: relative;

  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
}

.image-text__item img {
  border: 1px solid rgba(255, 255, 255, 0.2);
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 8px;
  overflow: hidden;
}

@media (--mobile) {
  .image-text__item img {
    position: relative;
  }
}

* + .image-text__numbers {
  padding-top: 16px;
}

.image-text-banner .image-text__numbers {
  position: absolute;
  bottom: 60px;
  right: 0;
}

.image-text__numbers__grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.image-text__numbers__grid .numbers-item,
.image-text__numbers > .numbers-item {
  margin-left: 0;
}

.image-text__numbers__grid + .numbers-item {
  margin-top: 32px;
}

.image-text__banner {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 360px;
}

.image-text__banner img {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.image-text__banner::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: linear-gradient(
    270deg,
    rgba(12, 36, 65, 0.85) 13.28%,
    rgba(12, 36, 65, 0.585136) 39.09%,
    rgba(12, 36, 65, 0) 90.28%
  );
}

@media (--mobile) {
  .image-text__banner::after {
    background: rgba(12, 36, 65, 0.5);
  }

  .image-text-banner .image-text__container {
    padding-bottom: 150px;
  }

  .image-text__gradient {
    height: 100%;
  }

  .image-text-banner .image-text__numbers {
    display: flex;
    width: 100%;
    bottom: 30px;
  }

  .image-text-banner .image-text__numbers__grid {
    width: 60%;
  }

  .image-text__numbers__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .image-text-banner .image-text__numbers > .numbers-item {
    margin-top: 0;
    width: 25%;
    margin-left: 20px;
  }
}
