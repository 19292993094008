.page-article__content {
  position: relative;

  color: var(--primary-500);
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
}

.article__content__container {
  position: relative;
  width: 66%;
  margin-left: 8.5%;
  background-color: white;
  border-radius: 8px;
  padding: 40px;
}

@media (--tablet) {
  .article__content__container {
    margin-left: 0;
    border-radius: 0;
    width: 100%;
  }

  .article__content__container {
    --margin-top: 0;
  }
}

@media (--mobile) {
  .article__content__container {
    padding: 20px;
  }

  .page-article__carpet,
  .page-article__content {
    margin-left: calc(-1 * var(--responsive-margin));
    margin-right: calc(-1 * var(--responsive-margin));
  }
}

.article__content__container img {
  width: 100%;
}

.article__sup-title {
  color: var(--orange-500);
  font-family: var(--font-family-heading);
  font-weight: 600;
  font-size: 15px;
  line-height: 1.2;
  text-transform: uppercase;
}

.article__title {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
}
@media (--tablet) {
  .article__title {
    font-size: 32px;
  }
}
@media (--mobile) {
  .article__title {
    font-size: 28px;
  }
}

* + .article__title {
  margin-top: 12px;
}
@media (--mobile) {
  * + .article__title {
    margin-top: 8px;
  }
}

.article__sub-title p {
  font-style: italic;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: 0.01em;
  opacity: 0.8;
}

.article__title + .article__sub-title {
  margin-top: 6px;
}

.article__date p {
  font-size: 15px;
  line-height: 130%;
  letter-spacing: 0.01em;
  opacity: 0.8;
}

.article__sub-title + .article__date {
  margin-top: 16px;
}

/* ARTICLE CONTENT */

.article__content__container > * + .article__content:first-child {
  margin-top: 0;
}

.article__content__container > * + .article__content {
  margin-top: 24px;
}

.article__content p {
  font-size: 15px;
  line-height: 140%;
  letter-spacing: 0.01em;
}

.article__content > * + p {
  margin-top: 24px;
}

.article__content a {
  color: var(--orange-500);
  text-decoration: none;
}
.article__content a:hover {
  text-decoration: underline;
}

.article__content * + h2,
.article__content * + h3 {
  margin-top: 12px;
}

.article__content > h2 {
  font-weight: 600;
  font-size: 32px;
  line-height: 1.4;
}

.article__content > h3 {
  font-weight: 500;
  font-size: 25px;
  line-height: 1.4;
}

.article__content > ul {
  padding-left: 36px;
}

.article__content > * + ul {
  margin-top: 24px;
}

.article__content > ul > li {
  padding-left: 6px;
}
.article__content > ul > li + li {
  margin-top: 4px;
}

.article__content > pre {
  width: 100%;

  font-weight: 700;
  font-family: var(--font-family);
  white-space: normal;
  line-height: 140%;
  letter-spacing: 0.01em;
  font-style: italic;
}

.article__content > * + pre {
  margin-top: 24px;
}

.article__content > pre::before {
  content: '“';
}

.article__content > pre::after {
  content: '”';
}

.article__content > * + div {
  margin-top: 24px;
}

.article__content > div {
  position: relative;

  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.article__content > div[data-oembed-type] > iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.article__content img {
  border-radius: 8px;
  max-height: 500px;
  object-fit: contain;
}
