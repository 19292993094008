/* MyriadPro-300 - latin */
@font-face {
  font-family: 'MyriadPro';
  font-style: normal;
  font-weight: 300;
  font-display: swap;

  src: local('MyriadPro Light'), local('MyriadPro-Light'),
    url('/font-faces/MyriadPro-Light.woff2') format('woff2'),
    url('/font-faces/MyriadPro-Light.woff') format('woff');
}

/* MyriadPro-regular - latin */
@font-face {
  font-family: 'MyriadPro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;

  src: local('MyriadPro Regular'), local('MyriadPro-Regular'),
    url('/font-faces/MyriadPro-Regular.woff2') format('woff2'),
    url('/font-faces/MyriadPro-Regular.woff') format('woff');
}

/* MyriadPro-Semibold - latin */
@font-face {
  font-family: 'MyriadPro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;

  src: local('MyriadPro Semibold'), local('MyriadPro-Semibold'),
    url('/font-faces/MyriadPro-Semibold.woff2') format('woff2'),
    url('/font-faces/MyriadPro-Semibold.woff') format('woff');
}

/* MyriadPro-bold - latin */
@font-face {
  font-family: 'MyriadPro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;

  src: local('MyriadPro Bold'), local('MyriadPro-Bold'),
    url('/font-faces/MyriadPro-Bold.woff2') format('woff2'),
    url('/font-faces/MyriadPro-Bold.woff') format('woff');
}

/* SourceSansPro-600 - latin */
@font-face {
  font-family: 'SourceSansPro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;

  src: local('SourceSansPro Semibold'), local('SourceSansPro-Semibold'),
    url('/font-faces/SourceSansPro-SemiBold.woff2') format('woff2'),
    url('/font-faces/SourceSansPro-SemiBold.woff') format('woff');
}

/* SourceSansPro-700 - latin */
@font-face {
  font-family: 'SourceSansPro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;

  src: local('SourceSansPro Bold'), local('SourceSansPro-Bold'),
    url('/font-faces/SourceSansPro-Bold.woff2') format('woff2'),
    url('/font-faces/SourceSansPro-Bold.woff') format('woff');
}
