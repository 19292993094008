.contact__top__sup-title {
  font-family: var(--font-family-heading);
  line-height: 1.3;
  font-weight: 600;
  font-size: 16px;
  color: var(--orange-500);
  text-transform: uppercase;
}
@media (--mobile) {
  .contact__top__sup-title {
    font-size: 15px;
  }
}

.contact__top__title {
  font-family: var(--font-family-heading);

  line-height: 1.2;
  font-weight: 700;
  font-size: 40px;
  margin-top: 4px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}
@media (--tablet) {
  .contact__top__title {
    font-size: 32px;
  }
}
@media (--mobile) {
  .contact__top__title {
    font-size: 28px;
  }
}

* + .contact__top__description {
  margin-top: 8px;
}
@media (--mobile) {
  * + .contact__top__description {
    margin-top: 16px;
  }
}

.contact__top__description,
.contact__top__description p {
  font-family: var(--font-family);
  line-height: 1.6;
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
}

.contact__top__description a {
  color: var(--white);
  text-decoration: none;
}
.contact__top__description a:hover {
  text-decoration: underline;
}

.contact__top__description strong {
  font-weight: 700;
}

@media (max-width: 1024px) {
  .contact__top__description {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .contact__top__description {
    font-size: var(--p-size);
  }
}
