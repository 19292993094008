* + .map-pratique > .max-width {
  padding-top: 76px;
  padding-bottom: 40px;
}

@media (--mobile) {
  * + .map-pratique > .max-width {
    padding-top: 35px;
  }
}

*:not(.pricing, .banner) + .map-pratique {
  margin-top: 60px;
}

.map-pratique {
  position: relative;
}

.map-pratique .max-width {
  max-width: unset;
}

.map-pratique__gradient {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 350px;
  background: linear-gradient(180deg, #1a3d68 0%, rgba(26, 61, 104, 0) 100%);
}

.map-pratique__title {
  text-align: center;
  position: relative;
  z-index: 1;
}

.map-pratique__title h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: var(--orange-500);
}

.map-pratique__list {
  list-style: none;
  display: grid;
  grid-template-columns: 1fr 2px 1fr;
  gap: 36px;
  text-align: center;
  position: relative;
  z-index: 1;
}

* + .map-pratique__list {
  margin-top: 40px;
}

.map-pratique__list__separator {
  height: 30px;
  background-color: var(--orange-500);
  border-radius: 20px;
}

.map-pratique__list__link button {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: white;
}

.map-pratique__list__link:first-of-type {
  text-align: right;
}

.map-pratique__list__link:last-of-type {
  text-align: left;
}

.map-pratique__slider__card {
  position: relative;
}

@keyframes slider__card-left-disappear {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50px);
  }
}

@keyframes slider__card-left-appear {
  0% {
    transform: translateX(50px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slider__card-right-disappear {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(50px);
  }
}

@keyframes slider__card-right-appear {
  0% {
    transform: translateX(-50px);
  }

  100% {
    transform: translateX(0);
  }
}

.map-pratique__slider__card__description {
  position: absolute;
  left: 0;
  right: 0;
  top: 52px;
}

.map-pratique__slider__card__description p {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.01em;
  width: 480px;
  margin: 0 auto;
}

.map-pratique__slider__card {
  position: relative;
  --height: 830px;
  --width: 80%;

  height: var(--height);
  width: var(--width);
  margin: 0 auto;
  padding-top: 180px;
  transition: 0.5s opacity ease-in;
}

.map-pratique__slider__card__map > img {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.map-pratique__slider__card__map
  > img:not(.map-pratique__slider__card__map_mobile) {
  background-color: var(--primary-500);
  transition: 0.2s opacity ease-in;
}

.map-pratique__slider__card__map
  > img:not(:first-of-type):not(.map-pratique__slider__card__map_mobile) {
  opacity: 0;
  z-index: 2;
  transition: opacity 0.2s ease-in-out;
}

.map-pratique__slider__card__pratique img {
  width: 100%;
  max-width: 600px;
}

.map-pratique__slider__card__region img {
  width: 100%;
  max-width: 1000px;
}

.map-pratique__slider__card__map_mobile {
  display: none;
}

.map-pratique__list__link:not(.map-pratique__list__link--active) button {
  opacity: 0.5;
}

.map-pratique__list__link:not(.map-pratique__list__link--active) button:hover {
  opacity: 1;
}

.map-pratique__slider__card:not(.map-pratique__slider__card--active) {
  display: none;
}

@media (max-width: 820px) {
  .map-pratique__slider__card {
    --width: 95%;
  }
}

@media (pointer: coarse) {
  .map-pratique__slider__card__region img {
    max-width: 850px;
  }

  .map-pratique__slider__card__map
    > img:not(.map-pratique__slider__card__map_mobile) {
    display: none;
  }

  .map-pratique__slider__card__map > map {
    display: none;
  }

  .map-pratique__slider__card__map_mobile {
    opacity: 1;
    display: block;
  }
}

@media (--mobile) {
  .map-pratique__slider__card__map
    > img:not(.map-pratique__slider__card__map_mobile) {
    display: none;
  }

  .map-pratique__slider__card__map > map {
    display: none;
  }

  .map-pratique__slider__card__map_mobile {
    opacity: 1;
    display: block;
  }

  .map-pratique__list {
    gap: 15px;
    width: 90%;
    margin: 0 auto;
  }

  * + .map-pratique__list {
    margin-top: 35px;
  }

  .map-pratique__list__link button {
    font-size: 16px;
    line-height: 20px;
  }

  .map-pratique__list__separator {
    height: 16px;
  }

  .map-pratique__list__link:first-of-type,
  .map-pratique__list__link:last-of-type {
    text-align: center;
  }

  .map-pratique__slider__card {
    --width: 100%;
    --height: 110vw;
    padding-top: 130px;
  }

  .map-pratique__slider__card__description {
    top: 35px;
  }

  .map-pratique__slider__card__description p {
    width: 90%;
    font-size: 15px;
  }

  .map-pratique__slider__card__province img {
    width: 75%;
  }

  .map-pratique__slider__card__region img {
    width: 90%;
  }
}
