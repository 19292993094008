* + .faq {
  margin-top: 90px;
}
@media (--tablet) {
  * + .faq {
    margin-top: 75px;
  }
}
@media (--mobile) {
  * + .faq {
    margin-top: 65px;
  }
}

.faq + .faq {
  margin-top: 80px;
}

.faq__container {
  display: grid;
  grid-template-columns: 2fr 3fr;
  letter-spacing: 0.01em;
  grid-gap: 24px;
}
@media (--mobile) {
  .faq__container {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
}
