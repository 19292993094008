.compact-convenient {
  --vertical-padding: 96px;

  position: relative;
  padding-top: var(--vertical-padding);
  padding-bottom: var(--vertical-padding);
  overflow: hidden;
  background-color: var(--primary-600);
}
@media (--tablet) {
  .compact-convenient {
    --vertical-padding: 64px;
  }
}
@media (--mobile) {
  .compact-convenient {
    --vertical-padding: 52px;
  }
}

.image-text + .compact-convenient {
  margin-top: var(--vertical-padding);
}

.compact-convenient__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.compact-convenient__background {
  background-image: url(/illustrations/background/carpet-pattern.jpg);
  background-size: auto 100%;
  background-repeat: repeat-x;
  background-position: center center;
}

.compact-convenient__background::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  background-image: linear-gradient(
      0deg,
      #0c2441 -0.34%,
      rgba(12, 36, 65, 0) 20.87%
    ),
    linear-gradient(180deg, #0c2441 0%, rgba(12, 36, 65, 0) 20.61%);
}

.compact-convenient__gradient {
  position: absolute;
  top: 0;
  left: 50%;
  width: 120%;
  height: 600px;
  background: #88308a;
  opacity: 0.3;
  filter: blur(300px);
  transform: translateX(-50%) translateY(-65%);
}

.compact-convenient__wrap {
  position: relative;

  display: grid;
  grid-gap: 52px;
  grid-template-columns: repeat(2, 1fr);
}

@media (--mobile) {
  .compact-convenient__wrap {
    grid-template-columns: 1fr;
    grid-gap: 26px;
  }
}

.compact-convenient__map {
  position: relative;

  height: 100%;
  min-height: 320px;
  overflow: hidden;
  border-radius: 8px;
}

.compact-convenient__map__wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.compact-convenient__map__wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.compact-convenient__content {
  width: 100%;
}

.compact-convenient__content .text {
  max-width: 60ch;
}

.compact-convenient__content * + .text {
  margin-top: 12px;
}

.compact-convenient__content-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
  row-gap: 32px;

  padding-top: 24px;
  list-style: none;
}
@media (--mobile) {
  .compact-convenient__content-list {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }
}

.compact-convenient__content-list-item-icon {
  width: 30px;
  height: 30px;
  align-self: baseline;
}
@media (--mobile) {
  .compact-convenient__content-list-item-icon {
    width: 36px;
    height: 36px;
  }
}
.compact-convenient__content-list-item-icon > * {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.compact-convenient__content-list-item {
  display: grid;

  grid-template-columns: 32px 1fr;
  grid-gap: 12px;
  align-items: center;
}

.compact-convenient__content-list-item-content {
  height: 100%;
}

.compact-convenient__content-list-item-content-title {
  font-size: 16px;
  text-transform: uppercase;
  font-family: var(--font-family-heading);
}

* + .compact-convenient__content-list-item-content-description {
  margin-top: 8px;
}
@media (--mobile) {
  * + .compact-convenient__content-list-item-content-description {
    margin-top: 6px;

    font-size: 15px;
  }
}

* + .compact-convenient__content-button {
  padding-top: 24px;
}
