.journey {
  --background-color: transparent;

  position: relative;

  padding-top: 80px;
  padding-bottom: 80px;

  background-color: var(--background-color);
  perspective: 800px;
}

.image-text + .journey {
  margin-top: 80px;
}
@media (--mobile) {
  .journey {
    padding-bottom: 40px;
  }
}

.journey--variant-history {
  z-index: 1;

  padding-bottom: 0;
}
.journey--variant-history,
.journey--variant-360-show {
  --background-color: #071526;
}
.journey--variant-timeline {
  --background-color: var(--primary-500);
  z-index: 1;
}

.journey--variant-360-show {
  padding-top: 80px;
  padding-bottom: 135px;
  overflow: hidden;
}
@media (--mobile) {
  .journey--variant-360-show {
    padding-top: 150px;
    padding-bottom: 40px;
  }
}
.journey--variant-vr {
  --background-color: var(--primary-500);

  padding-top: 72px;
  padding-bottom: 76px;
}

@media (--mobile) {
  .journey--variant-vr {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.journey__characters {
  position: absolute;
  top: 0;
  left: 0;

  transform: translateY(-7%);
}

.journey__characters img {
  width: 100%;
  height: 100%;
  object-fit: contain;

  user-select: none;
}

.journey__background--spectacle {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.journey__background--spectacle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  user-select: none;
}

.journey__background--pick {
  position: absolute;
  top: 0;
  right: 0;

  max-width: 228px;
  max-height: 499px;

  transform: translateY(-21%) translateX(-6%);
}

.journey__background--pick img {
  width: 100%;
  height: 100%;
  object-fit: contain;

  user-select: none;
}

.compact-convenient + .journey--variant-vr {
  margin-top: 0;
}

.journey--variant-plan {
  position: relative;
  right: 50%;
  left: 50%;

  width: 100vw;
  margin-right: -50vw;
  margin-bottom: 0;
  margin-left: -50vw;
  padding-top: 150px;
  padding-bottom: 280px;
  overflow: hidden;

  background-color: var(--primary-600);
}

.journey--variant-plan .journey__content {
  position: relative;
  z-index: 5;
  background: var(--primary-600);
  box-shadow: 10px 0px 10px var(--primary-600);
  padding: 15px 0 15px 0;
}

.light-primary {
  background-color: var(--primary-500);
}

.journey__gradient--plan {
  position: absolute;
  top: 0;

  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, #071526 15%, rgba(12, 36, 65, 0) 100%);
}

@media (--mobile) {
  .journey__gradient--plan {
    background: linear-gradient(to top, #071526 56%, rgba(12, 36, 65, 0) 100%);
  }
}

@media (max-width: 500px) {
  .journey--variant-plan {
    padding-top: 270px;
    padding-bottom: 160px;
  }

  .journey--variant-plan .journey__content .numbers {
    display: block;
    float: right;
    margin-top: 40px;
    z-index: 5;
    background: var(--primary-600);
    box-shadow: 10px 0px 10px var(--primary-600);
    padding: 15px;
  }

  .journey--variant-plan
    .journey__content
    .numbers
    .numbers-item:not(:first-of-type) {
    margin: 10px 0 0 0;
  }
}

.journey__content {
  max-width: 464px;
}

.journey--variant-360-show .journey__content {
  max-width: 464px;
  margin-left: auto;
}
@media (--mobile) {
  .journey--variant-360-show .journey__content {
    margin-left: 0;
  }
}

.journey__content .text h3 {
  color: white;
}

.journey--variant-timeline {
  padding: 100px !important;
}

.journey--variant-timeline .journey__content {
  position: relative;
  max-width: 45%;
  z-index: 11;
}

@media (--tablet) {
  .journey--variant-timeline .journey__content {
    max-width: 100%;
  }
}

.journey__number {
  font-size: 51px;
  font-weight: 700;
  font-family: var(--font-family-heading);
  color: var(--orange-500);
}

@media (--mobile) {
  .journey--variant-timeline {
    padding: 20px 0 !important;
  }

  .journey__number {
    font-size: 38px;
  }
}

.journey--variant-history .heading {
  max-width: 300px;
}

.journey * + .heading {
  margin-top: 16px;
}

.journey * + .text {
  margin-top: 16px;
}

.journey__content .button {
  margin-top: 16px;
}

.journey * + .numbers {
  margin-top: 40px;
}
@media (--mobile) {
  .journey * + .numbers {
    margin-top: 30px;
  }
}

.journey * + .timeline {
  margin-top: 100px;
}
@media (--mobile) {
  .journey * + .timeline {
    margin-top: 60px;
  }
}
