* + .article-selection {
  margin-top: 80px;
}
@media (--mobile) {
  * + .article-selection {
    margin-top: 60px;
  }
}

.article-selection__title {
  font-weight: 700;
  font-size: 40px;
  letter-spacing: 0.01em;
}
@media (--tablet) {
  .article-selection__title {
    font-size: 32px;
  }
}
@media (--mobile) {
  .article-selection__title {
    font-size: 24px;
  }
}

.article-selection__container {
  width: 100%;

  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(3, 1fr);
  letter-spacing: 0.01em;
}
@media (--tablet) {
  .article-selection__container {
    grid-gap: 16px;
  }
}
@media (--mobile) {
  .article-selection__container {
    grid-template-columns: 1fr;
    grid-gap: 32px;
  }
}

* + .article-selection__container {
  margin-top: 32px;
}

.article-selection__item {
  position: relative;
}

.article-selection__item__content {
  max-width: 345px;
}
@media (--tablet) {
  .article-selection__item__content {
    max-width: 100%;
  }
}

* + .article-selection__item__content {
  padding-top: 25px;
}
@media (--tablet) {
  * + .article-selection__item__content {
    padding-top: 20px;
  }
}
@media (--mobile) {
  * + .article-selection__item__content {
    padding-top: 16px;
  }
}

.article-selection__item__image {
  width: 100%;
  height: 180px;
  border-radius: 8px;
  overflow: hidden;
}
@media (--mobile) {
  .article-selection__item__image {
    height: 120px;
  }
}

.article-selection__item__image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.article-selection__item__title h3 {
  font-weight: 600;
  font-size: 20px;
  font-family: var(--font-family-heading);
  text-transform: uppercase;
}

.article-selection__item__title a {
  font-weight: 600;
  font-size: 20px;
  font-family: var(--font-family-heading);
  text-transform: uppercase;
  text-decoration: none;
  color: var(--white);
}

.article-selection__item__title a::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  z-index: 3;
}

.article-selection__item__title a:hover {
  text-decoration: underline;
}

.article-selection__item__sub-title p {
  font-weight: 400;
  font-style: normal;
  font-size: 15px;

  color: #ffffff;
}

.article-selection__item__title + .article-selection__item__sub-title {
  margin-top: 10px;
}

.article-selection__item__content > h3 > a {
  color: white;
  text-decoration: none;
}
.article-selection__item__content > h3 > a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
}

* + .article-selection__item__description {
  padding-top: 10px;
}

@media (--tablet) {
  * + .article-selection__item__description {
    padding-top: 14px;
  }
}

@media (--mobile) {
  * + .article-selection__item__description {
    padding-top: 12px;
  }
}

.article-selection__item__description > p {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
}

@media (--mobile) {
  .article-selection__item__description > p {
    font-weight: 500;
    font-size: 15px;
  }
}

* + .article-selection__item__button {
  padding-top: 8px;
}
