* + .convenient {
  padding-top: 100px;
}

@media (--tablet) {
  * + .convenient {
    padding-top: 65px;
  }
}

@media (--mobile) {
  * + .convenient {
    padding-top: 40px;
  }
}

.convenient__container {
  display: grid;
  grid-template-columns: 1fr 2fr;

  grid-gap: 48px;
  letter-spacing: 0.01em;
  line-height: 1.3;
}

@media (--tablet) {
  .convenient__container {
    grid-template-columns: 1fr;
  }
}

@media (--mobile) {
  .convenient__container {
    grid-gap: 32px;
  }
}
.convenient__image {
  position: relative;
  width: 464px;
  height: 517px;

  border-radius: 8px;
}

.convenient__image-shadow {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  overflow: hidden;

  box-shadow: rgba(223, 162, 68, 0.6) 0 0 9em;
  border-radius: 8px;
}

@media (--tablet) {
  .convenient__image {
    width: 100%;
    height: 270px;
  }
}

.convenient__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.convenient__content__top {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

@media (--mobile) {
  .convenient__content__top {
    grid-template-columns: 1fr;
    grid-gap: 36px;
  }
}

.convenient__content__top__schedules--disabled {
  display: none;
}

.convenient__content__top__schedules h2 {
  font-weight: 600;
  font-size: 24px;
}

.convenient__content__top__schedules
  h2
  + .convenient__content__top__schedules__content {
  padding-top: 11px;
}

.convenient__content__top__schedules__content {
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-gap: 12px;

  font-size: 16px;
  font-weight: 400;
}

.convenient__content__top__schedules__icon {
  --size: 25px;
  width: var(--size);
  height: var(--size);
}

.convenient__content__top__schedules__icon svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.convenient__content__top__schedules__item {
  display: flex;
  max-width: 215px;
}

.convenient__content__top__schedules__item
  + .convenient__content__top__schedules__item {
  padding-top: 16px;
}

.convenient__content__top__schedules__item__schedules {
  margin-left: auto;
}

.convenient__content__top__access {
  margin-left: auto;
  margin-right: auto;
}

.convenient__content__top__schedules--disabled
  + .convenient__content__top__access {
  margin-left: 0;
}

@media (--tablet) {
  .convenient__content__top__access {
    margin-left: 0;
  }
}

.convenient__content__top__access h2 {
  font-weight: 600;
  font-size: 24px;
}

.convenient__content__top__access
  h2
  + .convenient__content__top__access__content {
  padding-top: 11px;
}

.convenient__content__top__access__content {
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-gap: 12px;

  font-size: 16px;
  font-weight: 400;
}

.convenient__content__top__access__icon {
  --size: 25px;
  width: var(--size);
  height: var(--size);
}

.convenient__content__top__access__icon svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.convenient__content__top__access__text {
  max-width: 166px;
}

.convenient__content__top__access__text p + h3 {
  padding-top: 10px;
}

.convenient__content__top__access__link {
  padding-top: 16px;
}

/*.convenient__content__top__route {
  margin-right: auto;
}

.convenient__content__top__route h2 {
  font-weight: 600;
  font-size: 24px;
}

.convenient__content__top__route
  h2
  + .convenient__content__top__route__content {
  padding-top: 11px;
}

.convenient__content__top__route__content {
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-gap: 12px;

  font-size: 16px;
  font-weight: 400;
}

.convenient__content__top__route__icon {
  --size: 25px;
  width: var(--size);
  height: var(--size);
}

.convenient__content__top__route__icon svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.convenient__content__top__route__text {
  max-width: 166px;
}

.convenient__content__top__route__text p + h3 {
  padding-top: 10px;
}

@media (--tablet) {
  .convenient__content__top__route {
    margin-left: 0;
  }
}*/

.convenient__content__position {
  padding-top: 40px;
}

.convenient__content__position h2 {
  font-weight: 600;
  font-size: 24px;
}

.convenient__content__position h2 + .convenient__content__position__container {
  padding-top: 11px;
}

.convenient__content__position__container {
  display: flex;
}

@media (--mobile) {
  .convenient__content__position__container {
    flex-direction: column-reverse;
  }
}

.convenient__content__position__content {
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-gap: 12px;

  font-size: 16px;
  font-weight: 400;
}
@media (--mobile) {
  .convenient__content__position__content {
    padding-top: 18px;
  }
}

.convenient__content__position__text a {
  margin-top: 16px;
}
.convenient__content__position__icon {
  --size: 25px;
  width: var(--size);
  height: var(--size);
}

.convenient__content__position__icon svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.convenient__content__position__text p + button {
  margin-top: 10px;
}

.convenient__content__position__map {
  width: 272px;
  height: 192px;
  margin-left: 70px;
  margin-top: -42px;
  overflow: hidden;
}

@media (--mobile) {
  .convenient__content__position__map {
    width: 100%;
    height: 100%;
    margin-top: 0;
    margin-left: auto;
  }
}

.convenient__content__position__map img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;

  border: 1px solid rgba(255, 255, 255, 0.2);
}
