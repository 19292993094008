.text {
  font-family: var(--font-family);
  font-size: var(--p-size);
  font-weight: 400;
  line-height: var(--p-line-height);
  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.8);
}

.text h3 {
  font-size: 18px;
}

.text h3 + p {
  margin-top: 12px;
}

.text p + h3 {
  margin-top: 12px;
}

.text h3 + h3 {
  margin-top: 12px;
}

.text p + p {
  margin-top: 12px;
}

.text > p > a {
  color: var(--orange-500);
  text-decoration: none;
  z-index: 1;
}
.text > p > a:hover {
  text-decoration: underline;
  z-index: 1;
}
@media (--mobile) {
  .text p + p {
    margin-top: 8px;
  }
}
