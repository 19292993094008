.banner {
  position: relative;
  text-align: center;
}

* + .banner {
  margin-top: 80px;
}
.banner + .banner,
.pricing + .banner {
  margin-top: 0;
}
@media (--mobile) {
  * + .banner {
    margin-top: 32px;
  }
}

.banner.banner--first {
  margin-top: 0;
  padding-top: calc(var(--header-height) + 40px);
}

.js-can-use-transitions .banner--first {
  transition-duration: 400ms;
  transition-property: all;
}

@media (--mobile) {
  .banner--first {
    margin-top: var(--header-height);
  }
}

.journey + .banner {
  margin-top: 0;
}

.banner__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.banner__background img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  user-select: none;
}

.banner--carpet .banner__background {
  background-image: url(/illustrations/background/carpet-pattern.jpg);
  background-color: var(--primary-500);
  background-size: auto 100%;
  background-repeat: repeat-x;
  background-position: center center;
}

.banner__background__gradient {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: linear-gradient(
    0deg,
    var(--primary-600) 0%,
    rgba(12, 36, 65, 0) 20%
  );
}

.banner .max-width {
  --vertical-padding: 110px;

  position: relative;

  padding-top: var(--vertical-padding);
  padding-bottom: var(--vertical-padding);
}
@media (--mobile) {
  .banner .max-width {
    --vertical-padding: 80px;
  }
}

.banner__container .heading__title {
  color: var(--orange-500);
}

.banner__container .text {
  max-width: 55ch;
  margin-left: auto;
  margin-right: auto;
}
.banner__container * + .text {
  margin-top: 16px;
}

.banner__container__button {
  margin-top: 32px;
}
