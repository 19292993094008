.video-text {
  --aspect-ratio: 42.3%;
  --aspect-ratio-frac: 0.423;

  position: relative;
  padding-top: 80px;
  padding-bottom: 90px;
  overflow: hidden;
}
@media (--mobile) {
  .video-text {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.video-text__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 60px;
  align-items: center;
}

@media (--tablet) {
  .video-text__container {
    grid-template-columns: 1fr;
    grid-gap: 24px;
  }
}

.video-text__content {
  width: 100%;
  align-self: center;
}

.video-text.video--is-mobile .video__play__wrap,
.video-text.video--ready.video--is-mobile .video__play__wrap {
  opacity: 0;

  transition: 300ms opacity;
}

.video-text__video.video--fullscreen {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.video-text__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;

  background: radial-gradient(
      circle at 100%,
      #5868ae 0%,
      var(--primary-500) 40%
    ),
    linear-gradient(
      0deg,
      var(--primary-500) -0.34%,
      rgba(255, 255, 255, 0) 20.87%
    ),
    linear-gradient(
      180deg,
      var(--primary-500) 0%,
      rgba(255, 255, 255, 0) 20.61%
    );
}

.video-text__background img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;
  object-fit: cover;
  user-select: none;
}

.video-text__background__carpet {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-image: url(/illustrations/background/carpet-pattern.jpg);
  background-size: auto 100%;
  background-repeat: repeat-x;
  background-position: center center;
}

.video-text__background__gradient {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-image: linear-gradient(
      0deg,
      var(--primary-500) 0%,
      rgba(12, 36, 65, 0) 20%
    ),
    linear-gradient(180deg, var(--primary-500) 0%, rgba(12, 36, 65, 0) 20%);
}

.video-text__background__radial-gradient {
  position: absolute;
  top: 40%;
  left: 85%;

  width: 766px;
  height: 766px;

  background: var(--violet-500);
  opacity: 0.5;
  filter: blur(300px);

  transform: translate(-50%, -50%);
}

.video-text .text {
  max-width: 64ch;
}
* + .video-text .text {
  margin-top: 12px;
}

.video-text .text + .video-text__content__button {
  margin-top: 20px;
}

.video-text__video {
  position: relative;

  height: 0;
  padding-bottom: var(--aspect-ratio);
}

.video__play__wrap {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}

.video-text__video__wrap {
  position: absolute;
  top: 0;
  right: -3px;
  bottom: 0;
  left: -3px;

  overflow: hidden;
  border-radius: 8px;

  background-color: var(--primary-600);
}

.video__play {
  display: block;
  width: 100%;
  height: 100%;

  color: #ffffff;

  transition: 400ms opacity, 400ms transform;

  will-change: opacity, transform;
}
.video-text__video.video--started.video--playing .video__play {
  transform: scale(0.8);
  opacity: 0;

  transition: 300ms opacity, 300ms transform;

  pointer-events: none;
}

.video__play > button {
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  font-weight: 500;
  font-size: 20px;
  line-height: 1.5;

  cursor: pointer;

  user-select: none;
}
.video__play > button:focus {
  outline: none;
}
.video-text__video.video--started.video--playing .video__play > button {
  cursor: default;
}

@media (--mobile) {
  .video__play > button {
    position: absolute;
    top: 50%;
    left: 50%;

    font-size: 17px;

    transform: translateX(-50%) translateY(-50%);
  }
}

.video__play > button > .video__play__img {
  --size: 88px;

  display: flex;
  width: var(--size);
  height: var(--size);

  transition: 200ms transform;
}
@media (--mobile) {
  .video__play > button > .video__play__img {
    --size: 76px;
  }
}

.video__play > button > .video__play__img img {
  position: relative;

  width: 100%;
  height: 100%;
  object-fit: contain;

  transition: 200ms transform;
}
.video__play > button:hover > .video__play__img {
  transform: scale(1.1);

  transition: 200ms transform;
}
.video__play > button:active > .video__play__img img {
  transform: scale(0.95);

  transition: 100ms transform;
}

.video__fullscreen {
  transition: 300ms opacity, 300ms transform;
  position: absolute;
  right: 0;
  bottom: 0;
  transform: scale(1);

  padding: 20px;

  z-index: 4;
}

.video__fullscreen .video__fullscreen__img img {
  width: 20px;
  height: 20px;
}

.video__fullscreen:hover > .video__fullscreen__img {
  transform: scale(1.1);

  transition: 200ms transform;
}

.aspect-ratio.video--fullscreen {
  display: flex;
  align-items: center;
  justify-content: center;

  background: black;
}

.aspect-ratio.video--fullscreen .video__player {
  box-shadow: none;
}

.aspect-ratio.video--fullscreen .video-text__video__wrap {
  border-radius: 0;
}

.aspect-ratio.video--fullscreen .aspect-ratio__wrap {
  width: 100%;
  max-width: calc(100vh * (1 / (var(--aspect-ratio-frac))));

  background: black;
}

.aspect-ratio.video--fullscreen .aspect-ratio__wrap__wrap {
  position: relative;

  width: 100%;
  height: 0;
  padding-bottom: var(--aspect-ratio);

  background: black;
}

.aspect-ratio.video--fullscreen .video-text__video {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  border: 1px solid black;
  background: black;
}

.video__wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}

.video__wrap {
  align-items: center;
  height: 100%;
}

.video__player {
  position: relative;

  width: 100%;
  height: 0;
  padding-bottom: var(--aspect-ratio);
  box-shadow: 0 0 4em rgba(223, 162, 68, 0.5);
  border-radius: 8px;
}

.video__player__cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.video__player__cover > img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;
  object-fit: cover;

  user-select: none;
  border-radius: 8px;
}

.video__player__cover::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: block;

  content: '';
}

@media (--header-breakpoint) {
  .video__player__cover > img {
    bottom: 0;

    height: 100%;
  }

  .video__fullscreen {
    display: none;
  }
}

.video-text__video.video--ready .video-text__video__wrap {
  opacity: 1;

  transition: 200ms opacity;
}

@media (--mobile) {
  .video-text__video .video-text__video__wrap,
  .video-text__video.video--ready .video-text__video__wrap {
    opacity: 0;

    transition: 300ms opacity;
  }

  .video-text__video.video--started .video-text__video__wrap,
  .video-text__video.video--ready.video--started .video-text__video__wrap {
    opacity: 1;
  }
}

.video-text__video__wrap::after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  height: 300px;

  transition: 500ms opacity;

  content: '';
}
.video-text__video.video--started.video--playing
  .video-text__video__wrap::after {
  transition: 2000ms opacity;
}

.video-text__video__wrap > * {
  position: relative;

  top: 50%;

  transform: translateY(-50%);
}

.video__control {
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateY(-50%);
}

.video__control__play-pause {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;

  cursor: default;
}
.video-text__video.video--started.video--playing .video__control__play-pause {
  z-index: 3;
}
.video__control__play-pause:focus {
  outline: none;
}
