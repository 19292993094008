* + .pricing {
  position: relative;

  margin-top: 90px;
  padding: 48px 0;

  background-color: var(--white);
}
@media (--tablet) {
  * + .pricing {
    margin-top: 65px;
    padding: 32px 0;
  }
}
@media (--mobile) {
  * + .pricing {
    margin-top: 40px;
    padding: 24px 0;
  }
}

.pricing__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.pricing__background img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;
  object-fit: cover;
  user-select: none;
}

.pricing__container {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  grid-gap: 135px;

  color: var(--primary-500);
  letter-spacing: 0.01em;
}
@media (--tablet) {
  .pricing__container {
    grid-gap: 40px;
  }
}
@media (--mobile) {
  .pricing__container {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
}

.pricing__prices h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.3;
}

.pricing__prices h2 + .pricing__prices__container {
  padding-top: 9px;
}

.pricing__prices__container {
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-gap: 12px;
}

@media (--tablet) {
  .pricing__prices__container {
    grid-gap: 6px;
  }
}

.pricing__prices__icon {
  --size: 25px;
  width: var(--size);
  height: var(--size);
  margin-top: 2px;
}

@media (--tablet) {
  .pricing__prices__icon {
    --size: 22px;
  }
}

.pricing__prices__icon svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.pricing__prices__text {
  line-height: 1.35;
}

.pricing__prices__item {
  display: flex;
  padding-bottom: 12px;
}

.pricing__prices__item + .pricing__prices__item {
  padding-top: 12px;

  border-top: 0.5px solid rgba(12, 36, 65, 0.2);
}

.pricing__prices__item__content h3 {
  font-weight: 700;
  font-size: 16px;
}

.pricing__prices__item__description {
  font-weight: 400;
  font-size: 14px;
}

.pricing__prices__item__price {
  font-weight: 700;
  font-size: 16px;

  margin-left: auto;
}

.pricing__prices__link {
  float: right;
  margin-top: 20px;
}
@media (--mobile) {
  .pricing__prices__link {
    float: unset !important;
    margin-top: 5px;
  }
}

.pricing__prices__link .button__text p {
  font-size: 17px;
  margin-top: 5px;
}

.pricing__secondary h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.3;
}
.pricing__secondary h2 + .pricing__secondary__container {
  padding-top: 9px;
}

.pricing__secondary__container {
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-gap: 10px;
}
@media (--tablet) {
  .pricing__secondary__container {
    grid-gap: 4px;
  }
}

.pricing__secondary__icon {
  --size: 25px;

  width: var(--size);
  height: var(--size);
  margin-top: 2px;
}
@media (--tablet) {
  .pricing__secondary__icon {
    --size: 22px;
  }
}

.pricing__secondary__icon svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.pricing__secondary__text {
  line-height: 1.35;
}

.pricing__secondary__text__content {
  font-size: 14px;
}

.pricing__secondary__text__content > h3 + p {
  padding-top: 5px;
}

.pricing__secondary__text__content > p + p {
  padding-top: 5px;
}

.pricing__secondary__text__content > p + h3 {
  padding-top: 5px;
}
.pricing__secondary__text__content > h3 + h3 {
  padding-top: 5px;
}

.pricing__secondary__text__content + .pricing__secondary__text__link {
  padding-top: 14px;
}

.pricing__secondary__text__link + .pricing__secondary__text__sub-text {
  padding-top: 14px;
}

.pricing__secondary__text__sub-text {
  font-weight: 400;
  font-size: 13px;
  line-height: 1.2;
  color: rgba(12, 36, 65, 0.8);
}
