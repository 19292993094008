* + .partners-list {
  margin-top: 64px;
}

.partners-list + .partners-list {
  padding-top: 0;
}

.partners-list {
  background-color: var(--white);
}

.partners-list__container {
  display: flex;
  flex-direction: column;
  color: var(--primary-500);
}

.partners-list__top {
  max-width: 464px;
}

.partners-list__top h2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 1.29;
}

.partners-list__top h2 + p {
  padding-top: 11px;
}

.partners-list__top p {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  letter-spacing: 0.01em;
  opacity: 0.8;
}

.partners-list__top + .partners-list__list {
  padding-top: 40px;
}

@media (--tablet) {
  .partners-list__top + .partners-list__list {
    padding-top: 30px;
  }
}

@media (--mobile) {
  .partners-list__top + .partners-list__list {
    padding-top: 20px;
  }
}

.partners-list__list {
  display: grid;
  grid-gap: 16px;
  overflow: hidden;
}

.partners-list__list--3 {
  grid-template-columns: repeat(3, 1fr);
}

.partners-list__list--4 {
  grid-template-columns: repeat(4, 1fr);
}

.partners-list__list--5 {
  grid-template-columns: repeat(5, 1fr);
}

@media (--tablet) {
  .partners-list__list--3 {
    grid-template-columns: repeat(2, 1fr);
  }

  .partners-list__list--4 {
    grid-template-columns: repeat(2, 1fr);
  }

  .partners-list__list--5 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (--mobile) {
  .partners-list__list--3 {
    grid-template-columns: 1fr;
  }

  .partners-list__list--4 {
    grid-template-columns: 1fr;
  }

  .partners-list__list--5 {
    grid-template-columns: 1fr;
  }
}

.partners-list__item {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  border-radius: 8px;
  border: 1px solid rgba(12, 36, 65, 0.2);

  width: 100%;
  height: 100%;
  padding: 24px;
}

@media (--tablet) {
  .partners-list__item {
    padding: 20px;

    width: unset;
    height: unset;
  }
}

@media (--mobile) {
  .partners-list__item {
    padding: 16px;
  }
}

.partners-list__item__logo {
  height: 86px;
  flex-shrink: 0;
  align-self: baseline;
}

.partners-list__item__logo--img-middle {
  align-self: center;
}

@media (--mobile) {
  .partners-list__item__logo--img-middle {
    align-self: baseline;
  }
}

.partners-list__item__logo img {
  width: 100%;
  height: 100%;

  object-fit: contain;
}

.partners-list__item__logo + .partners-list__item__content {
  padding-top: 16px;
}

.partners-list__item__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.partners-list__item__top {
  flex: 1;
}

.partners-list__item__content h3 {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.3;
}

.partners-list__item__title a {
  color: var(--primary-500);
  text-decoration: none;
}

.partners-list__item__title a::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  z-index: 3;
}

.partners-list__item__title a:hover {
  text-decoration: underline;
}

.partners-list__item__title + p {
  padding-top: 8px;
}

.partners-list__item__content p {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;

  letter-spacing: 0.01em;
}

.partners-list__item__top + .partners-list__item__bottom {
  padding-top: 16px;
}
