@custom-media --tablet (max-width: 1024px);
@custom-media --mobile (max-width: 768px);
@custom-media --header-breakpoint (max-width: 900px);

@custom-media --wider-than-desktop (min-width: 1251px);
@custom-media --wider-than-tablet (min-width: 1025px);
@custom-media --wider-than-mobile (min-width: 769px);

:root {
  --font-family: 'MyriadPro', sans-serif;
  --font-family-heading: 'SourceSansPro', sans-serif;

  --header-height: 120px;
  --height-flash-info: 52px;
  --height-flash-info-mobile: 64px;
  --page-horizontal-padding: 24px;
  --page-left-padding: calc(var(--page-horizontal-padding));
  --page-left-padding: calc(
    var(--page-horizontal-padding) + env(safe-area-inset-left)
  );
  --page-right-padding: calc(var(--page-horizontal-padding));
  --page-right-padding: calc(
    var(--page-horizontal-padding) + env(safe-area-inset-right)
  );

  --white: #ffffff;

  --primary-500: #0c2441;
  --primary-600: #0b1a2b;

  --secondary-400: #e8cc82;
  --secondary-500: #d8bd73;
  --secondary-800: #534622;

  --orange-500: #dfa244;
  --yellow-500: #e8d64e;
  --violet-500: #5868ae;
  --red-500: #bf4440;
  --purple-500: #af4e8d;

  --p-size: 16px;
  --title-size: 32px;

  --p-line-height: 1.3;
  --title-line-height: 1.25;
}
