.press-resource__list {
  display: flex;
  flex-direction: column;

  list-style: none;
}

.press-resource__item {
  position: relative;

  display: flex;
  padding-bottom: 16px;
  letter-spacing: 0.01em;
}
@media (--tablet) {
  .press-resource__item {
    display: block;
  }
}

.press-resource__item + .press-resource__item {
  padding-top: 16px;

  border-top: 0.5px solid rgba(255, 255, 255, 0.5);
}
.press-resource__title + .press-resource__item {
  margin-top: 24px;
}

.press-resource__item__container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.press-resource__item__container__left {
  flex: 1;
}

.press-resource__item__icon {
  --size: 25px;

  width: var(--size);
  height: var(--size);
  margin-right: 14px;
  align-self: baseline;
}
@media (--tablet) {
  .press-resource__item__icon {
    --size: 28px;

    margin-bottom: 11px;
  }
}

.press-resource__item__icon svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.press-resource__item__title {
  font-size: 16px;
  line-height: 1.3;
  color: var(--white);
}

.press-resource__item__title a {
  color: var(--white);
  text-decoration: none;
}

.press-resource__item__title a::after {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;

  content: '';
}

* + .press-resource__item__content {
  margin-top: 4px;
}

.press-resource__item__content p {
  font-size: 14px;
  line-height: 1.3;
  color: rgba(255, 255, 255, 0.8);
}

.press-resource__item__button {
  flex-shrink: 0;
}

* + .press-resource__item__button {
  margin-left: 12px;
}

.press-resource__item .press-resource__item__button .button {
  --background-color: transparent;

  display: block;
}
.press-resource__item:not(.press-resource__item--disabled):hover
  .press-resource__item__button
  .button {
  --background-color: rgba(225, 184, 62, 0.2);
}
@media (--mobile) {
  .press-resource__item .press-resource__item__button .button {
    --horizontal-padding: 16px;
    --height: 40px;
  }
}

.press-resource__item--disabled .press-resource__item__button .button {
  pointer-events: none;
}
