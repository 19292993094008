.faq__content__item {
  position: relative;
  user-select: none;
}

* + .faq__content__item {
  border-top: 0.5px solid rgba(255, 255, 255, 0.5);
}

.faq__content__item__header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  transition: opacity 100ms;
  padding: 16px 0;
  position: relative;
}

.faq__content__item__header:hover {
  opacity: 0.8;
}

.faq__content__item__header__title__link:hover {
  cursor: pointer;
}

.faq__content__item__header__title__link::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
}

.faq__content__item__header__title {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.35;
  width: 85%;
}

.faq__content__item__header__icon {
  max-width: 36px;
  max-height: 36px;
}
@media (--mobile) {
  .faq__content__item__header__icon {
    min-width: 26px;
    min-height: 26px;
  }
}

.faq__content__item__header__icon svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.faq__content__item__content {
  padding-top: 0;
  padding-bottom: 0;
  max-height: 0px;
  overflow-y: hidden;

  transition: all 0.3s cubic-bezier(0, 1, 0, 1);
}

.faq__content__item__content--current {
  height: auto;
  max-height: 600px;
  padding-bottom: 16px;

  opacity: 1;
  transition: all 0.4s ease;
}

.faq__content__item__content > p + ul {
  padding-top: 11px;
}

.faq__content__item__content > ul > li {
  margin-left: 20px;
}

.faq__content__item__content,
.faq__content__item__content p {
  line-height: 1.4;
}
