.press-resource__press-kit {
  position: relative;

  max-width: 272px;
  padding-top: 51px;
  letter-spacing: 0.01em;
  line-height: 1.35;
}
@media (--mobile) {
  .press-resource__press-kit {
    grid-row-start: 1;
    margin-left: auto;
    margin-right: auto;
    max-width: 220px;

    text-align: center;
  }
}

.press-resource__press-kit__image {
  position: relative;
  width: 272px;
  height: 0;
  padding-bottom: 137.87%;
  overflow: hidden;

  border-radius: 4px;
}
@media (--tablet) {
  .press-resource__press-kit__image {
    width: 100%;
  }
}

.press-resource__press-kit__image img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.press-resource__press-kit__title {
  font-size: 16px;
}

* + .press-resource__press-kit__title {
  margin-top: 24px;
}

.press-resource__press-kit__title a {
  text-decoration: none;
  color: var(--white);
}

.press-resource__press-kit__title a::after {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;

  content: '';
}

.press-resource__press-kit__description {
  font-size: 14px;
}

* + .press-resource__press-kit__description {
  margin-top: 2px;
}

* + .press-resource__press-kit__button {
  margin-top: 16px;
}
.press-resource__press-kit:hover
  .press-resource__press-kit__button
  .button
  .button__text {
  text-decoration: underline;
}
