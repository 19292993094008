.subject + .head-contact {
  margin-top: 25px;
}

.head-contact {
  --width: 600px;
  --height: 140px;

  width: var(--width);
  min-height: var(--height);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  padding: 25px 50px;
  margin: 0 auto;
}

.head-contact__sup-title {
  font-family: "Myriad Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.01em;
}

.head-contact__title {
  font-family: "Myriad Pro", sans-serif;

  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  letter-spacing: 0.01em;
}

.head-contact__grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.head-contact__title + .head-contact__grid {
  margin-top: 15px;
}

.head-contact__grid__block {
  --icon-width: 20px;
  --icon-height: 20px;
  --icon-margin-right: 8px;
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.head-contact__grid__block svg {
  margin-right: var(--icon-margin-right);
  width: var(--icon-width);
  height: var(--icon-height);
  transform: translateY(5px);
}

.head-contact__grid__block a {
  text-decoration: none;
  color: white;
}

.head-contact__grid__block a:hover {
  text-decoration: underline;
}

@media (--mobile) {
  .head-contact {
    width: 90%;
  }

  .head-contact__grid {
    display: block;
  }

  .head-contact__grid__block {
    display: block;
    text-align: center;
  }

  .head-contact__grid__block + .head-contact__grid__block {
    margin-top: 15px;
  }
}
