.head-contact + .gallery {
  margin-top: 32px;
}

.gallery {
  margin-top: 54px;
}
.gallery__container {
  height: 348px;

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 16px;
}

@media (--tablet) {
  .gallery__container {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 8px;
    height: 100%;
  }
}

@media (--mobile) {
  .gallery__container {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 8px;
  }
}

.gallery__item {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

@media (--mobile) {
  .gallery__item {
    border-radius: 6px;
  }
}

.gallery__item:nth-of-type(2) {
  grid-column-start: 2;
  grid-column-end: 4;

  grid-row-start: 1;
  grid-row-end: 3;
}
@media (--tablet) {
  .gallery__item:nth-of-type(2) {
    grid-column-start: 3;
    grid-column-end: 5;
  }
}

@media (--mobile) {
  .gallery__item:nth-of-type(2) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 4;
  }
}

.gallery__item:nth-of-type(6) {
  grid-column-start: 4;
  grid-column-end: 6;
}

@media (--tablet) {
  .gallery__item:nth-of-type(1) {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .gallery__item:nth-of-type(5) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .gallery__item:nth-of-type(6) {
    grid-column-start: 3;
    grid-column-end: 5;
  }
}

@media (--mobile) {
  .gallery__item:nth-of-type(1) {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .gallery__item:nth-of-type(2) {
    height: 200px;
  }

  .gallery__item:nth-of-type(4) {
    height: 200px;
  }

  .gallery__item:nth-of-type(5) {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .gallery__item:nth-of-type(6) {
    grid-column-start: 1;
    grid-column-end: 3;
    height: 200px;
  }
}

.gallery__item::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: var(--primary-600);
  opacity: 0;

  transition: 400ms opacity;

  content: '';
  pointer-events: none;
}
.gallery__item:hover::before {
  opacity: 0.7;
}

.gallery__item > a {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;

  color: white;
  font-weight: 600;

  text-align: center;
  text-decoration: none;

  opacity: 0;

  transition: 400ms opacity;
  z-index: 1;
}

.gallery__item > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  user-select: none;
}
.gallery__item:hover > a {
  opacity: 1;
}

.gallery__item > a > img {
  display: block;
  width: 32px;
  height: 32px;
  margin-bottom: 14px;
}
