.header {
  --header-padding-top: 30px;
  --gap: 22px;
  --logo-width: 100px;

  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;

  text-align: center;

  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}
@media (--tablet) {
  .header {
    --gap: 14px;
    --logo-width: 80px;
  }
}
@media (--header-breakpoint) {
  .header {
    --header-padding-top: 24px;
  }
}

.header__gradient {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  height: 120px;
  background-image: linear-gradient(
    to bottom,
    rgba(5, 18, 34, 0.85),
    cubic-bezier(0.6, 0.12, 0.74, 0.89),
    rgba(5, 18, 34, 0)
  );
  pointer-events: none;
  opacity: 0;
  transform: translateY(-100px);
}

.js-can-use-transitions .header__gradient {
  transition-duration: 400ms;
  transition-property: opacity, transform;
}

.header--dark .header__gradient {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  height: 120px;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.85),
    cubic-bezier(0.6, 0.12, 0.74, 0.89),
    rgba(255, 255, 255, 0)
  );
  pointer-events: none;
  opacity: 0;
  transform: translateY(-100px);
}

.js-can-use-transitions .header--dark .header__gradient {
  transition-duration: 400ms;
  transition-property: opacity, transform;
}

.header--scrolled .header__gradient {
  opacity: 1;
  transform: translateY(0);
}
@media (--header-breakpoint) {
  .header__gradient {
    height: 100px;
  }
}

.js-can-use-transitions .header > .max-width {
  transition-duration: 400ms;
  transition-property: all;
}

.header__grid {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr var(--logo-width) 1fr;
}
@media (--header-breakpoint) {
  .header__grid {
    grid-template-columns: 1fr;
  }
}

.header__logo {
  width: 100%;
  padding-top: var(--header-padding-top);
  margin-bottom: var(--header-padding-top);
}

.header__logo img {
  --size: 60px;

  width: var(--size);
  height: var(--size);
  object-fit: contain;
  margin: auto;
}

.header__logo__dark {
  display: none;
}

.header__logo__light {
  display: block;
}

.header--dark .header__logo__light {
  display: none;
}

.header--dark .header__logo__dark {
  display: block;
}

@media (--tablet) {
  .header__logo img {
    --size: 50px;
  }
}

.header__part {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (--header-breakpoint) {
  .header__part {
    display: none;
  }
}

.header__part--left {
  justify-content: flex-end;
}

.header__nav {
  display: flex;
  align-items: center;
}

* + .header__nav {
  margin-left: 28px;
}

.header__nav__item {
  height: 100%;
  list-style: none;
}

.header__nav__item a {
  display: flex;
  align-items: center;
  height: 100%;
  padding: var(--header-padding-top) 0;
  padding-left: calc(var(--gap) / 2);
  padding-right: calc(var(--gap) / 2);

  font-family: var(--font-family-heading);
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: var(--white);
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  position: relative;
}

.header--dark .header__nav__item a {
  display: flex;
  align-items: center;
  height: 100%;
  padding-top: var(--header-padding-top);
  padding-left: calc(var(--gap) / 2);
  padding-right: calc(var(--gap) / 2);

  font-family: var(--font-family-heading);
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: var(--primary-500);
  text-decoration: none;
  text-align: center;
  cursor: pointer;
}

@media (pointer: fine) {
  .header__nav__item a span {
    position: relative;
  }

  .header__nav__item a span::after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    left: 0;
    height: 2px;
    background: white;
    transform: scale(0);

    bottom: -5px;
  }

  .js-can-use-transitions .header__nav__item a span::after {
    transition: transform 0.2s ease-in-out;
  }

  .header--dark a span::after {
    background: var(--primary-500);
  }

  .header__nav__item a:hover span::after {
    transform: scale(1);
  }
}

@media (--tablet) {
  .header__nav__item a {
    font-size: 15px;
  }
}

.header__ctas {
  padding-top: 4px;

  list-style: none;
}
* + .header__ctas {
  margin-left: calc(var(--gap) / 2);
}

.header__ctas .button {
  max-width: 160px;
  padding-left: 20px;
}

.header__mobile-cta {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;

  display: none;
  align-items: center;
  justify-content: center;
  padding-left: var(--page-left-padding);
}
@media (--header-breakpoint) {
  .header__mobile-cta {
    display: flex;
  }
}

.header__mobile-cta .button {
  --height: 40px;
  --horizontal-padding: 12px;
  --vertical-padding: 0;
}
.header__mobile-cta .button__right-icon {
  padding-left: 0;
  border: none;
}
.header__mobile-cta * + .button__right-icon {
  margin-left: 9px;
}
.header__mobile-cta .button__right-icon::before {
  display: none;
}
.header__mobile-cta .button__right-icon svg {
  --size: 12px;

  position: relative;
  top: 1px;
}

.header__burger {
  position: absolute;
  top: 0;
  right: 0;

  display: none;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  margin-left: auto;

  background: none;
  border: none;
  cursor: pointer;
}
.header__burger.header__burger--touch-down {
  transform: scale(0.85);
}
.js-can-use-transitions .header__burger.header__burger--touch-down {
  transition-duration: 180ms;
}
@media (--header-breakpoint) {
  .header__burger {
    display: flex;
  }
}
.js-focus-visible .header__burger:focus:not(.focus-visible) {
  outline: none;
}

.header__burger div {
  position: relative;

  margin-top: -4px;
}

.header__burger div,
.header__burger div::before,
.header__burger div::after {
  display: block;
  width: 26px;
  height: 2px;

  background-color: var(--white);
  border-radius: 2px;
}

.header--dark .header__burger div,
.header--dark .header__burger div::before,
.header--dark .header__burger div::after {
  display: block;
  width: 26px;
  height: 2px;

  background-color: var(--primary-500);
  border-radius: 2px;
}

.header__burger div::before,
.header__burger div::after {
  position: absolute;
  top: -9px;
  right: 0;
  bottom: auto;
  left: 0;

  content: "";
}
.header__burger div::after {
  top: auto;
  bottom: -9px;
}

.flash-info {
  display: flex;
  align-items: stretch;
  width: 100%;
  height: var(--height-flash-info);
  padding: 6px 0 6px 32px;
  overflow: hidden;

  color: black;

  background-color: var(--secondary-500);

  pointer-events: auto;
  transform: translateY(0);
}
.js-can-use-transitions .flash-info {
  transition-duration: 400ms;
  transition-property: all;
}
@media (--mobile) {
  .flash-info {
    height: var(--height-flash-info-mobile);
  }

  .flash-info {
    padding: 6px var(--page-right-padding) 6px var(--page-left-padding);
  }
}

.flash-info:not(.flash-info--show) {
  pointer-events: none;
  transform: translateY(-52px);
}
@media (--mobile) {
  .flash-info:not(.flash-info--show) {
    transform: translateY(-64px);
  }
}

.flash-info:not(.flash-info--show) + .max-width {
  transform: translateY(-52px);
}
@media (--mobile) {
  .flash-info:not(.flash-info--show) + .max-width {
    transform: translateY(-64px);
  }
}

.flash-info--scrolled {
  pointer-events: none;
  transform: translateY(-52px);
}
@media (--mobile) {
  .flash-info--scrolled {
    transform: translateY(-64px);
  }
}

.flash-info--scrolled + .max-width {
  transform: translateY(-52px);
}
@media (--mobile) {
  .flash-info--scrolled + .max-width {
    transform: translateY(-64px);
  }
}

.flash-info__inner {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 32px 1fr;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
}
@media (--mobile) {
  .flash-info__inner {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
}

.flash-info__inner > img {
  width: 26px;
  height: 26px;
  object-fit: contain;
}
@media (--mobile) {
  .flash-info__inner > img {
    display: none;
  }
}

.flash-info__content {
  align-items: center;

  color: black;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (--mobile) {
  .flash-info__content {
    font-size: 15px;
  }
}

.flash-info__content > :first-child::before {
  font-weight: bold;
  font-style: normal;

  content: "Flash info : ";
}

.flash-info > button {
  display: none;
  height: auto;
  padding-right: 32px;
  padding-left: 32px;

  align-items: center;

  cursor: pointer;
}
.js-focus-visible .flash-info > button:focus:not(.focus-visible) {
  outline: none;
}
@media (--mobile) {
  .flash-info > button {
    height: auto;
    padding-right: 16px;
    padding-left: 6px;
  }
}

.flash-info > button > img {
  width: 26px;
  height: 26px;
  object-fit: contain;
}

.flash-info__content a {
  color: black;
}
