.journey__vr-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  user-select: none;
  pointer-events: none;
}
@media (--mobile) {
  .journey__vr-background {
    bottom: 30%;
  }
}

.journey__vr-background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (--mobile) {
  .journey__vr-background img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: -70%;

    width: 170%;

    opacity: 0.8;
  }
}

.journey__vr-background__gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to right,
    #0c2441,
    ease-in-out,
    rgba(12, 36, 65, 0) 100%
  );
}
@media (--mobile) {
  .journey__vr-background__gradient {
    background-image: linear-gradient(
        to right,
        #0c2441,
        ease-in-out,
        rgba(12, 36, 65, 0) 100%
      ),
      linear-gradient(to top, #0c2441, rgba(12, 36, 65, 0) 50%);
  }
}
