.article__sidebar {
  position: absolute;
  top: calc(var(--margin-top) + 24px);
  right: 0;
  width: 24%;
}

@media (--tablet) {
  .article__sidebar {
    display: none;
  }
}

.article__sidebar__title {
  font-family: var(--font-family-heading);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}

.article__sidebar__title + .article__sidebar__description {
  margin-top: 16px;
}

.article__sidebar__description {
  font-family: var(--font-family);
  font-size: 15px;
  line-height: 130%;
  letter-spacing: 0.01em;
  opacity: 0.8;
}

.article__sidebar__description + .article__sidebar__button {
  margin-top: 16px;
}

.article__sidebar__button > .button {
  width: 100%;
}

.article__sidebar__button + .article__sidebar__footer {
  margin-top: 24px;
}

.article__sidebar__footer__text {
  font-family: var(--font-family);
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: #0c2441;
  opacity: 0.8;
}

.article__sidebar__footer__text + .article__sidebar__footer__buttons {
  margin-top: 16px;
}

.article__sidebar__footer__buttons img {
  filter: invert(1);
  height: 20px;
  width: 20px;
}
