* + .press-resource {
  margin-top: 80px;
}
@media (--mobile) {
  * + .press-resource {
    margin-top: 24px;
  }
}

.press-resource__container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 60px;
}
@media (--tablet) {
  .press-resource__container {
    grid-template-columns: 5fr 2fr;
    grid-gap: 32px;
  }
}
@media (--mobile) {
  .press-resource__container {
    grid-template-columns: 1fr;
  }
}
