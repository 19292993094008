.timeline-container {
  --offset: 128px;

  position: relative;

  width: 100vw;
  margin-top: 72px;
  margin-left: calc(-1 * var(--offset));
  overflow: hidden;
}
@media (--tablet) {
  .timeline-container {
    margin-top: 32px;
    overflow: visible;
  }
}
@media (--mobile) {
  .timeline-container {
    position: relative;
    left: 50%;
    right: 50%;

    margin-top: 8px;
    margin-left: -50vw;
    margin-right: -50vw;
  }
}

.timeline-container::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  width: var(--offset);
  content: '';

  background-image: linear-gradient(90deg, #071526 40%, rgba(0, 0, 0, 0));
}
@media (--tablet) {
  .timeline-container::before {
    display: none;
  }
}

.timeline {
  position: relative;

  justify-content: center;
  padding-left: var(--offset);
  padding-bottom: 80px;
  overflow: visible;

  transition: transform 0.8s ease-in-out;
}
@media (--mobile) {
  .timeline {
    position: relative;
    left: 50%;
    right: 50%;

    width: 100vw;
    margin-left: -50vw;
    margin-right: -50vw;
    padding-bottom: 52px;
    padding-left: 20px;
    overflow-x: auto;

    transform: translate3d(0px, 0px, 0px) !important;
  }
}

.timeline__line {
  position: absolute;
  top: 20px;
  left: var(--offset);

  width: calc(220px * 4);
  height: 2px;

  background-image: linear-gradient(
    to right,
    rgba(82, 92, 104, 1) 30%,
    rgba(82, 92, 104, 0) 80%
  );
  transform: translateX(var(--offset)), translateY(-50%);
  transition: width 0.8s ease-in-out;
}
@media (--tablet) {
  .timeline__line {
    background-image: linear-gradient(
      to right,
      rgba(82, 92, 104, 1) 70%,
      rgba(82, 92, 104, 0) 90%
    );
  }
}
@media (--mobile) {
  .timeline__line {
    background-image: none;
    background-color: rgba(82, 92, 104, 1);
  }
}

.timeline__current-line {
  position: absolute;
  top: 20px;
  left: var(--offset);
  z-index: 2;

  width: 0;
  height: 2px;

  background-color: var(--white);
  transform: translateY(-50%);
  transition: width ease-in-out 0.5s;
}

@media (--mobile) {
  .timeline__line,
  .timeline__current-line {
    top: 40px;
    left: 20px;
  }
}

.timeline__list {
  display: flex;
  list-style: none;
}

.timeline-item {
  --size: 220px;

  position: relative;

  min-width: var(--size);
  max-width: var(--size);
  padding-top: 28px;
  padding-right: 20px;

  transition: opacity 0.2s ease-in-out;
}
@media (--mobile) {
  .timeline-item {
    --size: 230px;

    display: block !important;
    opacity: unset !important;
    min-width: var(--size);
    max-width: var(--size);
  }
}

.timeline-item:nth-child(n + 4) {
  opacity: 0.2;
}

.timeline-item__circle {
  --size: 32px;
  --disc-color: #565553;
  --circle-color: #8b8a88;

  position: absolute;
  top: 20px;
  z-index: 3;

  width: var(--size);
  height: var(--size);

  background-image: radial-gradient(
    circle at center,
    var(--disc-color) 43%,
    #0c0c0a 43.1%
  );
  border-radius: 50%;
  border: 2px solid var(--circle-color);
  transition: all 200ms ease-in;
  transform: translateY(-50%) scale(0.7);
  transform-origin: center left;
}
@media (--mobile) {
  .timeline-item__circle {
    top: 40px;

    width: var(--size);
    height: var(--size);
  }
}

.timeline-item:hover .timeline-item__circle {
  --circle-color: var(--white);
}

.timeline-item.current .timeline-item__circle {
  --disc-color: var(--white);
  --circle-color: var(--white);

  transform: translateX(-10%) translateY(-50%) scale(1);
}

.timeline-item__button::after {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 4;

  content: '';
}

.timeline-item__button::after:hover {
  cursor: pointer;
}

.timeline-item__date {
  margin-top: 20px;

  font-family: var(--font-family-heading);
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.05em;
}
@media (--mobile) {
  .timeline-item__date {
    margin-top: 28px;
  }
}

.timeline-item__title {
  font-family: var(--font-family-heading);
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.05em;
}
@media (--mobile) {
  .timeline-item__title {
    max-width: 70%;

    font-size: 15px;
  }
}

* + .timeline-item__title {
  margin-top: 16px;
}
@media (--mobile) {
  .timeline-item__title {
    margin-top: 8px;
  }
}

.timeline-item__description {
  font-family: var(--font-family);
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.01em;
  line-height: 1.4;
}
@media (--mobile) {
  .timeline-item__description {
    font-size: 13px;
  }
}

* + .timeline-item__description {
  margin-top: 8px;
}
