.footer {
  background-color: var(--dark);
  color: var(--background-color);
  font-size: 14px;
  background-color: var(--primary-600);
}

.footer__content {
  padding-top: 40px;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(4, 1fr);
}
@media (--tablet) {
  .footer__content {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (--mobile) {
  .footer__content {
    grid-template-columns: 1fr;
    grid-gap: 32px;
    text-align: center;
  }
}

.footer__infos__logo {
  display: block;
  width: 87px;
  height: 67px;
}
@media (--mobile) {
  .footer__infos__logo {
    margin: auto;
  }
}

.footer__infos__logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.footer__infos__address {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
  line-height: 1.2;
}
* + .footer__infos__address {
  margin-top: 16px;
}

.footer__infos__address * + p {
  margin-top: 4px;
}

.footer__infos__address strong {
  color: var(--white);
}

.footer__social {
  display: flex;
  align-items: center;
  list-style: none;
}
@media (--mobile) {
  .footer__social {
    margin: auto;

    justify-content: center;
  }
}

* + .footer__social {
  margin-top: 16px;
}

.footer__social li {
  height: 20px;
}

.footer__social li a {
  height: 100%;
  padding-right: 24px;
}

@media (--mobile) {
  .footer__social li a {
    padding: 0 12px;
  }
}

.footer__social li a img {
  height: 100%;
  transition: 200ms transform;
  user-select: none;
}
.footer__social li a:hover img {
  transform: scale(1.15);
}
.footer__social li a:active img {
  transform: scale(1);
  transition: 100ms transform;
}

.footer__column__item__title {
  font-family: var(--font-family-heading);
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

* + .footer__column__item__links {
  margin-top: 12px;
}

.footer__column__item__links p {
  line-height: 1;
}

.footer__column__item__links a {
  --vertical-padding: 4px;

  display: block;
  padding-top: var(--vertical-padding);
  padding-bottom: var(--vertical-padding);

  color: var(--white);
  text-decoration: none;
  opacity: 0.7;
  transition: 200ms opacity;
}
.footer__column__item__links a:hover {
  opacity: 1;
  text-decoration: underline;
}
@media (--mobile) {
  .footer__column__item__links a {
    --vertical-padding: 6px;
  }
}

.footer * + .newsletter-form {
  margin-top: 20px;
}

.footer__bottom {
  display: flex;
  align-items: center;
  margin-top: 56px;
  padding-top: 12px;
  padding-bottom: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}
@media (--tablet) {
  .footer__bottom {
    padding-top: 24px;
    flex-direction: column;
  }
}
@media (--mobile) {
  .footer__bottom {
    margin-top: 32px;
    padding-bottom: 32px;
    text-align: center;
  }
}

.footer__bottom__copyright {
  color: var(--white);
  opacity: 0.6;
}

.footer__bottom__links {
  --gap: 32px;

  display: flex;
  list-style: none;
  flex-wrap: wrap;
}
@media (--tablet) {
  * + .footer__bottom__links {
    --gap: 16px;
  }
}
@media (--mobile) {
  .footer__bottom__links {
    justify-content: center;
  }
}

* + .footer__bottom__links {
  margin-left: 32px;
}
@media (--tablet) {
  * + .footer__bottom__links {
    margin-top: 12px;
    margin-left: 0;
  }
}

.footer__bottom__links li a,
.footer__bottom__links li button {
  --vertical-padding: 6px;

  display: block;
  padding-top: var(--vertical-padding);
  padding-right: calc(var(--gap) / 2);
  padding-bottom: var(--vertical-padding);
  padding-left: calc(var(--gap) / 2);

  color: var(--white);
  opacity: 0.6;
  text-decoration: none;
  transition: 200ms opacity;
}
.footer__bottom__links li a:hover,
.footer__bottom__links li button:hover {
  opacity: 0.8;
  text-decoration: underline;
}
