.journey__history-background {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 1500px;
}
@media (--mobile) {
  .journey__history-background {
    width: 140%;
  }
}

.journey__history-background__wrap__image.current {
  opacity: 1;
}

.journey__history-background__wrap {
  --width-percentage: 80%;
  --width-fration: 0.8;

  position: relative;
  width: var(--width-percentage);
  padding-bottom: calc(var(--width-fration) * 52.32%);
  margin-left: auto;
  overflow: hidden;

  user-select: none;
  pointer-events: none;
}
@media (--mobile) {
  .journey__history-background__wrap {
    --width-percentage: 70%;
    --width-fration: 0.7;
  }
}

.journey__history-background__wrap__image {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 1s ease-in-out;
}

.journey__history-background__gradient {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -1px;
  left: -1px;
  background-image: linear-gradient(
      to right,
      var(--background-color) 0%,
      rgba(7, 21, 38, 0) 70%
    ),
    linear-gradient(to top, var(--background-color) 0%, rgba(7, 21, 38, 0) 50%);
}
@media (--mobile) {
  .journey__history-background__gradient {
    background-image: linear-gradient(
        to right,
        var(--background-color) 0%,
        rgba(7, 21, 38, 0) 70%
      ),
      linear-gradient(
        to top,
        var(--background-color) 20%,
        rgba(7, 21, 38, 0) 60%
      );
  }
}
