.contact__social {
  display: flex;
  align-items: center;
  max-width: 152px;
  list-style: none;
}

* + .contact__social {
  margin-top: 40px;
}
@media (--mobile) {
  * + .contact__social {
    margin-top: 24px;
  }
}

.contact__social > li {
  height: 20px;
}

.contact__social > li > a {
  height: 100%;
  padding-right: 32px;
}

.contact__social > li > a > img {
  height: 100%;

  transition: 200ms transform;

  user-select: none;
}

.contact__social > li > a:hover > img {
  transform: scale(1.15);
}

.contact__social > li > a:active > img {
  transform: scale(1);

  transition: 100ms transform;
}
