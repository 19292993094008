.last-articles__item {
  position: relative;

  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;

  border-radius: 8px;
}

.last-articles__item--with-background {
  display: grid;
  grid-column: 1 / 13;
  grid-template-columns: repeat(2, 1fr);
}
@media (--tablet) {
  .last-articles__item--with-background {
    grid-column: 1 / 3;
    column-gap: 2px;
  }
}
@media (--mobile) {
  .last-articles__item--with-background {
    grid-column: 1 / 2;
    grid-template-columns: 1fr;
  }
}

.last-articles__item--background-black {
  background-color: #040a0e;
}
.last-articles__item--background-grey {
  background-color: #eaecef;
}

.last-articles__item:nth-of-type(2) {
  grid-column: 1 / 5;
}
.last-articles__item:nth-of-type(3) {
  grid-column: 5 / 9;
}
.last-articles__item:nth-of-type(4) {
  grid-column: 9 / 13;
}
.last-articles__item:nth-of-type(5) {
  grid-column: 1 / 7;
}
.last-articles__item:nth-of-type(6) {
  grid-column: 7 / 13;
}
@media (--tablet) {
  .last-articles__item:nth-of-type(2) {
    grid-column: 1 / 2;
  }
  .last-articles__item:nth-of-type(3) {
    grid-column: 2 / 3;
  }
  .last-articles__item:nth-of-type(4) {
    grid-column: 1 / 2;
  }
  .last-articles__item:nth-of-type(5) {
    grid-column: 2 / 3;
  }
  .last-articles__item:nth-of-type(6) {
    grid-column: 1 / 3;
  }
}
@media (--mobile) {
  .last-articles__item:nth-of-type(2),
  .last-articles__item:nth-of-type(3),
  .last-articles__item:nth-of-type(4),
  .last-articles__item:nth-of-type(5),
  .last-articles__item:nth-of-type(6) {
    grid-column: 1 / 2;
  }
}

.last-articles__item__image {
  --height: 184px;

  width: 100%;
  height: var(--height);
}
@media (--mobile) {
  .last-articles__item__image {
    --height: 140px;
  }
}

.last-articles__item--with-background .last-articles__item__image {
  height: 100%;
}
@media (--mobile) {
  .last-articles__item--with-background .last-articles__item__image {
    max-height: var(--height);
  }
}

.last-articles__item__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  user-select: none;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
}

.last-articles__item--with-background .last-articles__item__image img {
  border-radius: 0;
}

.last-articles__item__content {
  --horizontal-padding: 0;
  --vertical-padding: 0;

  padding-top: var(--vertical-padding);
  padding-right: var(--horizontal-padding);
  padding-bottom: var(--vertical-padding);
  padding-left: var(--horizontal-padding);

  letter-spacing: 0.01em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.last-articles__item--background-black .last-articles__item__content {
  --vertical-padding: 32px;
}

* + .last-articles__item__content {
  margin-top: 16px;
}

.last-articles__item--background-black .last-articles__item__content,
.last-articles__item--background-grey .last-articles__item__content {
  --horizontal-padding: 32px;

  margin-top: 0;
}
@media (--tablet) {
  .last-articles__item--background-black .last-articles__item__content,
  .last-articles__item--background-grey .last-articles__item__content {
    --vertical-padding: 24px;
    --horizontal-padding: 24px;
  }
}

.last-articles__item--with-background .last-articles__item__content__top {
  display: flex;
  flex-direction: column;

  flex: 0;
}

* + .last-articles__item__content__bottom {
  margin-top: 14px;
}
.last-articles__item--with-background
  *
  + .last-articles__item__content__bottom {
  margin-top: 16px;
}

.last-articles__item__content__top {
  flex: 1;
}

.last-articles__item__content__sup-title {
  font-family: var(--font-family-heading);
  color: var(--orange-500);
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
}

.last-articles__item__content__title {
  transition: opacity 200ms;
}
.last-articles__item:hover .last-articles__item__content__title {
  opacity: 0.6;
}

* + .last-articles__item__content__title {
  margin-top: 10px;
}

.last-articles__item__content__title,
.last-articles__item__content__title a {
  color: var(--primary-500);
  font-weight: 700;
  font-size: 24px;
  text-decoration: none;
}
@media (--mobile) {
  .last-articles__item__content__title,
  .last-articles__item__content__title a {
    font-size: 20px;
  }
}

.last-articles__item--background-black .last-articles__item__content__title,
.last-articles__item--background-black .last-articles__item__content__title a {
  color: var(--white);
}

.last-articles__item__content__title a::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.last-articles__item__content__description {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
  color: rgba(12, 36, 65, 0.8);

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.last-articles__item__content__description--truncate-3 {
  -webkit-line-clamp: 3;
}
.last-articles__item__content__description--truncate-5 {
  -webkit-line-clamp: 5;
}

* + .last-articles__item__content__description {
  margin-top: 8px;
}

.last-articles__item--background-black
  .last-articles__item__content__description {
  color: rgba(255, 255, 255, 0.8);
}
