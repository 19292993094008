@custom-media --hero-breakpoint (max-width: 1850px);
@custom-media --hero-mobile (max-width: 1850px);

.hero {
  position: relative;
}

.hero__gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(360deg, var(--primary-500) 0%, rgba(12, 36, 65, 0) 100%);
}

.hero__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.hero__background__images {
  list-style: none;
}

.hero__background__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
}
.js-can-use-transitions .hero__background__image {
  transition: opacity 500ms;
}
.hero__background__image--active {
  opacity: 1;
}

.hero__background img {
  width: 100%;
  height: 100%;
  object-fit: cover;

  user-select: none;
  opacity: 0.8;
}

.hero__container {
  position: relative;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-top: calc(var(--header-height) + 64px);
  padding-bottom: 64px;

  z-index: 1;
}
.js-can-use-transitions .hero__container {
  transition-duration: 400ms;
  transition-property: all;
}

.main--flash-info .hero__container {
  padding-top: calc(var(--header-height) + var(--height-flash-info) + 64px);
}
@media (--hero-breakpoint) {
  .hero__container {
    grid-template-columns: 1fr;
    padding-top: calc(var(--header-height) + 60px);
  }

  .main--flash-info .hero__container {
    grid-template-columns: 1fr;
    padding-top: calc(var(--header-height) + var(--height-flash-info) + 60px);
  }
}
@media (--tablet) {
  .hero__container {
    padding-top: calc(var(--header-height) + 32px);
  }

  .main--flash-info .hero__container {
    padding-top: calc(var(--header-height) + var(--height-flash-info) + 32px);
  }
}
@media (--mobile) {
  .hero__container {
    padding-top: var(--header-height);
    padding-bottom: 32px;
  }
  .main--flash-info .hero__container {
    padding-top: calc(var(--header-height) + var(--height-flash-info-mobile));
    padding-bottom: 32px;
  }
}

.hero__left {
  width: 100%;
  align-self: flex-start;
}

.hero__title h1 {
  max-width: 100ch;
  font-weight: 700;
  font-family: var(--font-family-heading);
  font-size: 44px;
  line-height: 1.25;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}
@media (--tablet) {
  .hero__title h1 {
    font-size: 40px;
  }
}
@media (--mobile) {
  .hero__title h1 {
    font-size: 28px;
  }
}

.hero__description {
  font-family: var(--font-family);
  max-width: 48ch;
  font-weight: 400;
  font-size: 22px;
  line-height: 1.3;
  letter-spacing: 0.01em;
  color: var(--white);
  opacity: 0.8;
  line-height: 140%;
}
* + .hero__description {
  margin-top: 12px;
}

@media (--mobile) {
  .hero__description {
    font-size: 16px;
  }
}

.hero__buttons {
  display: flex;
  align-items: center;
  margin-top: 16px;
}
@media (--mobile) {
  .hero__buttons {
    flex-direction: column;
    align-items: flex-start;
  }
}

.hero__buttons > * + * {
  margin-left: 16px;
}
@media (--mobile) {
  .hero__buttons > * + * {
    margin-top: 16px;
    margin-left: 0;
  }
}

.button--right-arrow-circle {
  margin-right: 16px;
}

.hero--without-image .hero__description,
.hero--without-image-orange .hero__description {
  max-width: 100%;
}
