* + .last-articles {
  margin-top: 48px;
}

.last-articles__container,
.last-articles__container__section {
  width: 100%;
}

.last-articles__container__section {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 24px;
  row-gap: 40px;
  padding-bottom: 40px;
}
@media (--tablet) {
  .last-articles__container__section {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 24px;
  }
}
@media (--mobile) {
  .last-articles__container__section {
    grid-template-columns: 1fr;
    row-gap: 24px;
  }
}
