.not-found {
  --margin-top: 40px;

  margin-top: var(--margin-top);
}

.not-found--first {
  --margin-top: calc(var(--header-height) + 80px);
  transition-duration: 400ms;
  transition-property: all;
}

@media (--mobile) {
  .not-found--first {
    --margin-top: calc(var(--header-height) + 40px);
  }
}

.main--flash-info .not-found--first {
  --margin-top: calc(var(--header-height) + var(--height-flash-info) + 80px);
}

@media (--mobile) {
  .main--flash-info .not-found--first {
    --margin-top: calc(
      var(--header-height) + var(--height-flash-info-mobile) + 40px
    );
  }
}

.not-found__button {
  text-align: center;
}

.title + .not-found__button {
  margin-top: 40px;
}

.not-found__image {
  position: relative;
  height: 500px;
}

.not-found__image img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: auto;
}

.max-width + .not-found__image {
  margin-top: 40px;
}

@media (--mobile) {
  .not-found__image {
    position: relative;
    height: 250px;
  }

  .title + .not-found__button {
    margin-top: 16px;
  }

  .max-width + .not-found__image {
    margin-top: 26px;
  }
}
