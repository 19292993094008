@custom-media --hero-right-mobile (max-width: 900px);

.hero__right {
  --hero-slider-gap: 16px;

  --hero-slider-width-1: 150px;
  --hero-slider-width-2: 170px;

  --hero-slider-height-1: 230px;
  --hero-slider-height-2: 264px;

  position: relative;

  align-self: flex-end;
  display: grid;
  height: var(--hero-slider-height-2);
  margin-top: 230px;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
}
@media (--hero-breakpoint) {
  .hero__right {
    margin-top: 32px;
  }
}
@media (max-width: 1500px) {
  .hero__right {
    grid-template-columns: 40% 1fr;
  }
}
@media (max-width: 1100px) {
  .hero__right {
    grid-template-columns: 30% 1fr;
  }
}
@media (--tablet) {
  .hero__right {
    margin-top: 64px;
    grid-template-columns: 250px 1fr;
  }
}
@media (--hero-right-mobile) {
  .hero__right {
    --hero-slider-gap: 8px;

    --hero-slider-width-1: 110px;
    --hero-slider-width-2: 110px;

    --hero-slider-height-1: 190px;
    --hero-slider-height-2: 210px;

    position: relative;
    left: 50%;

    width: 100vw;
    margin-top: 32px;
    margin-right: -50vw;
    margin-left: -50vw;
    grid-template-columns: 186px 1fr;
    grid-gap: 12px;
  }
}

.hero__right__content {
  position: relative;

  display: flex;
  align-items: flex-end;
  text-align: right;
}
@media (--tablet) {
  .hero__right__content {
    text-align: left;
  }
}
@media (--hero-right-mobile) {
  .hero__right__content {
    z-index: 1;

    margin-left: var(--page-left-padding);

    pointer-events: none;
  }
}

@media (--hero-right-mobile) {
  .hero__right__content::before {
    position: absolute;
    top: -32px;
    right: -100px;
    bottom: -32px;
    left: calc(-1 * var(--page-left-padding));

    content: "";
    display: block;

    background-image: linear-gradient(
      to right,
      rgba(5, 18, 34, 1),
      cubic-bezier(0.6, 0.12, 0.74, 0.89),
      rgba(5, 18, 34, 0)
    );
  }
}

.hero__right__content__item {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transform: translateY(20%);
  pointer-events: none;
}
.js-can-use-transitions .hero__right__content__item {
  transition: opacity 200ms, transform 200ms;
}
.hero__right__content__item--active {
  opacity: 1;
  transform: translateY(0%);
  pointer-events: auto;
}
.js-can-use-transitions .hero__right__content__item--active {
  transition: opacity 800ms, transform 800ms;
}

.hero__right__content__item .ruler {
  margin-left: auto;
}
@media (--tablet) {
  .hero__right__content__item .ruler {
    margin-left: 0;
  }
}

.hero__sub-title {
  font-family: var(--font-family-heading);
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.05em;
  text-align: right;
}
@media (--tablet) {
  .hero__sub-title {
    text-align: left;
  }
}

* + .hero__sub-title {
  margin-top: 16px;
}

.hero__sub-description {
  max-width: 300px;
  margin-left: auto;

  text-align: right;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.4;
  letter-spacing: 0.01em;
}
@media (--tablet) {
  .hero__sub-description {
    text-align: left;
  }
}

* + .hero__sub-description {
  margin-top: 16px;
}

.hero__right__content * + .button {
  margin-top: 16px;
}
.hero__right__content .button .button__text {
  font-weight: 400;
}

.hero__right__slider {
  position: relative;

  width: 100%;
  height: var(--hero-slider-height-2);
  grid-gap: 16px;
}
@media (--hero-right-mobile) {
  .hero__right__slider {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    overflow: visible;
  }
}

@media (--hero-right-mobile) {
  .hero__right__slider__scroll {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.hero__right__slider__wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: flex-end;
  width: 1000px;
}
@media (--hero-right-mobile) {
  .hero__right__slider__wrap {
    position: static;

    display: flex;
    overflow-x: auto;
    width: 100%;
    padding-left: 230px;
    padding-right: 100px;
    -webkit-overflow-scrolling: touch;
  }
}

.hero__right__slider__wrap::-webkit-scrollbar {
  display: none;
}

@media (--hero-right-mobile) {
  .hero__right__slider__wrap::after {
    content: "\A0";
    display: block;
    width: 0;
    margin-left: var(--page-left-padding);
  }
}

.hero__right__slider__slide {
  position: relative;

  width: var(--hero-slider-width-1);
  height: var(--hero-slider-height-1);
  overflow: hidden;

  border-radius: 8px;
  border: 1px solid transparent;
  transform: translateZ(0px);
}
.js-can-use-transitions .hero__right__slider__slide {
  transition: 300ms;
}
.hero__right__slider__slide--current {
  width: var(--hero-slider-width-2);
  height: var(--hero-slider-height-2);

  border: 1px solid rgba(255, 255, 255, 0.2);
}
* + .hero__right__slider__slide {
  margin-left: var(--hero-slider-gap);
}
@media (--hero-right-mobile) {
  .hero__right__slider__slide {
    flex: 0 0 var(--hero-slider-width-1);
    opacity: 0.5;
  }
  .hero__right__slider__slide--current {
    flex: 0 0 var(--hero-slider-width-2);
    opacity: 1;
  }
}

.hero__right__slider__slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero__right__slider__slide__link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  color: transparent;
  user-select: none;
}
