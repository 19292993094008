.three-videos {
  padding: 59px 0;
  position: relative;
}

.three-videos__gradient {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-image: radial-gradient(
    rgba(223, 162, 68, 0.6) 0%,
    rgba(12, 36, 65, 0) 50%
  );
}

.three-videos__video-container {
  position: relative;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  overflow: hidden;
}
@media (--mobile) {
  .three-videos__video-container {
    grid-template-columns: 1fr;
  }
}

.three-videos__item {
  --border-radius: 10px;

  position: relative;

  overflow: hidden;

  border-radius: var(--border-radius);
  border: 1px solid rgba(255, 255, 255, 0.2);
}
@media (--mobile) {
  .three-videos__item {
    height: 250px;
  }
}

.three-videos__img-gradient {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  height: 100%;

  background-image: linear-gradient(
    180deg,
    rgba(6, 17, 31, 0) 48%,
    rgba(6, 17, 31, 0.9) 100%
  );
}
@media (--mobile) {
  .three-videos__img-gradient {
    background-image: linear-gradient(
      180deg,
      rgba(6, 17, 31, 0) 22%,
      rgba(6, 17, 31, 0.9) 100%
    );
  }
}

.three-videos__item__image {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.three-videos__item__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 600ms;
}
.three-videos__item:hover .three-videos__item__image img {
  transform: scale(1.03);
}

.three-videos__item__content {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.three-videos__item__content__container {
  width: 100%;
  padding: 20px 24px 24px 24px;

  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  border-radius: 0 0 calc(var(--border-radius) - 1px)
    calc(var(--border-radius) - 1px);
  transition: background-color 200ms, backdrop-filter 600ms;
}
.three-videos__item:hover .three-videos__item__content__container {
  backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, 0.35);
}
.three-videos__item:active .three-videos__item__content__container {
  background-color: rgba(255, 255, 255, 0.25);
}

.three-videos__item__content a {
  text-decoration: none;
  color: var(--white);
  width: 100%;
}

.three-videos__item__content a::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.three-videos__item__icon svg {
  --size: 25px;

  width: var(--size);
  height: var(--size);
  object-fit: contain;
}

.three-videos__item__content__container__title {
  line-height: var(--title-line-height);
  font-family: var(--font-family-heading);
  font-size: 24px;
}

@media (--tablet) {
  .three-videos__item__content__container__title {
    font-size: 18px;
  }

  .three-videos__item__content__container__description {
    font-size: 10px;
  }
}

.three-videos__item__content__container__description {
  margin-top: 11px;
  line-height: var(--p-line-height);
  font-family: var(--font-family);
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
}
