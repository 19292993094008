.texte {
  font-size: 16px;
  line-height: 1.25;
  color: white;
}

* + .texte {
  margin-top: 64px;
}
@media (--mobile) {
  * + .texte {
    margin-top: 48px;
  }
}

.texte .max-width {
  --max-width: 700px;
}

.texte h2 {
  font-weight: 600;
  font-size: 24px;
}
@media (--mobile) {
  .texte h2 {
    font-size: 20px;
  }
}

.texte * + h2 {
  margin-top: 24px;
}

.texte * + p {
  margin-top: 16px;
}

.texte a {
  color: var(--orange-500);
  text-decoration: none;
}
.texte a:hover {
  text-decoration: underline;
}
