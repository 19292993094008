.title {
  position: relative;
  z-index: 1;
  margin-top: 70px;
}
@media (--mobile) {
  .title {
    margin-top: 88px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}

.title--white {
  background-color: var(--white);
}

.title--transparent-black {
  background-color: transparent;
  color: var(--primary-500);
}

.title--first {
  margin-top: calc(var(--header-height) + 40px);
}

.js-can-use-transitions .title--first {
  transition-duration: 400ms;
  transition-property: all;
}

@media (--mobile) {
  .title--first {
    margin-top: var(--header-height);
  }
}

.main--flash-info .title--first {
  margin-top: calc(var(--header-height) + var(--height-flash-info) + 40px);
}

@media (--mobile) {
  .main--flash-info .title--first {
    margin-top: calc(var(--header-height) + var(--height-flash-info-mobile));
  }
}

.title__container {
  text-align: center;
}

.title__sup-title {
  font-family: var(--font-family-heading);
  color: var(--orange-500);
  text-transform: uppercase;
}

.title__title {
  font-size: 40px;
  font-family: var(--font-family-heading);
  font-weight: 700;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}
@media (--tablet) {
  .title__title {
    font-size: 32px;
  }
}

* + .title__title {
  margin-top: 4px;
}

.title--white .title__title {
  color: var(--primary-500);
}

.title__description {
  max-width: 646px;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 0.01em;

  font-size: var(--p-size);
  line-height: var(--p-line-height);
}
.title--white .title__description {
  color: rgba(12, 36, 65, 0.8);
}

* + .title__description {
  margin-top: 16px;
}

.title__description * + p {
  margin-top: 8px;
}

.title__description a {
  color: var(--orange-500);
  text-decoration: none;
}
.title--white .title__description a {
  color: rgba(12, 36, 65, 0.8);
}

.title__description a:hover {
  text-decoration: underline;
}

.title__arrow {
  position: absolute;
  width: 25%;
  height: auto;
  top: 15%;
  transform: translateY(-45%) translateX(90%);
  right: 0px;
}

@media (--mobile) {
  .title__arrow {
    width: 15%;
  }
}

@media (max-width: 425px) {
  .title__arrow {
    top: 54%;
    transform: translateY(-45%) translateX(-10%);
    right: 0px;
  }
}

@media (max-width: 390px) {
  .title__arrow {
    top: 50%;
    transform: translateY(-50%) translateX(0);
    right: 0px;
  }
}

.title__arrow img {
  width: 100%;
  height: 100%;
}
