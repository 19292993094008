.faq__header {
  max-width: 368px;
}

@media (--mobile) {
  .faq__header {
    max-width: 100%;
  }
}

.ruler + .faq__header__title {
  margin-top: 16px;
}

.faq__header__title {
  font-family: var(--font-family-heading);
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-transform: uppercase;
}
@media (--mobile) {
  .faq__header__title {
    font-size: 24px;
  }
}

.faq__header__description {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
  color: var(--orange-500);
}

* + .faq__header__description {
  margin-top: 12px;
}

* + .faq__header__cta {
  margin-top: 16px;
}
