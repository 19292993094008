.contact {
  padding-top: calc(var(--header-height) + 40px);
}

.js-can-use-transitions .contact {
  transition-duration: 400ms;
  transition-property: all;
}

@media (--mobile) {
  .contact {
    padding-top: var(--header-height);
  }
}

.main--flash-info .contact {
  padding-top: calc(var(--header-height) + var(--height-flash-info) + 40px);
}
@media (--mobile) {
  .main--flash-info .contact {
    padding-top: calc(var(--header-height) + var(--height-flash-info-mobile));
  }
}

.contact__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 98px;
  grid-row-gap: 52px;

  letter-spacing: 0.01em;
}
@media (--tablet) {
  .contact__container {
    grid-gap: 52px;
  }
}
@media (--mobile) {
  .contact__container {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
}

.contact__content {
  position: relative;

  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 72ch;
}
