.contact__position {
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-gap: 18px;
}
* + .contact__position {
  margin-top: 26px;
}

.contact__position__icon {
  --size: 26px;
  width: var(--size);
  height: var(--size);
}

.contact__position__icon svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.contact__position__content__title {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.3;
}

* + .contact__position__content__description {
  padding-top: 2px;
}

* + .contact__position__content__link {
  padding-top: 14px;
}

.contact__position__content__description {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 1.3;
}
