.max-width {
  --max-width: 1136px;

  position: relative;

  width: 100%;
  max-width: calc(
    var(--max-width) + var(--page-left-padding) + var(--page-right-padding)
  );
  margin-right: auto;
  margin-left: auto;
  padding-left: var(--page-left-padding);
  padding-right: var(--page-right-padding);
}
