.numbers {
  display: flex;
  justify-content: space-between;
}

.numbers-item {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0px;
}

* + .numbers-item {
  margin-left: 8px;
}
@media (--mobile) {
  * + .numbers-item {
    margin-left: 10px;
  }
}

.numbers-item__title {
  font-family: var(--font-family-heading);
  font-size: 26px;
  font-weight: 700;
  color: var(--orange-500);
}
@media (--mobile) {
  .numbers-item__title {
    font-size: 24px;
  }
}

.numbers-item__title em {
  font-weight: 400;
  font-size: 15px;
  font-style: normal;
}

.numbers-item__title strong {
  position: relative;
  top: -6px;
  font-weight: 400;
  font-size: 15px;
}

.numbers-item__description {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: 0.01em;
  opacity: 0.8;
}

* + .numbers-item__description {
  margin-top: 2px;
}
