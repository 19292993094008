.page-article {
  --responsive-margin: 24px;
  --margin-top: 140px;
}
@media (--tablet) {
  .page-article {
    --margin-top: 0;
  }
}

.page-article__mobile-gradient {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;

  display: none;
  height: 150px;
  pointer-events: none;

  margin-top: var(--height-flash-info);

  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.85),
    cubic-bezier(0.6, 0.12, 0.74, 0.89),
    rgba(255, 255, 255, 0)
  );
}
@media (--tablet) {
  .page-article__mobile-gradient {
    display: block;
  }
}

.main--flash-info .page-article__heading {
  margin-top: calc(var(--header-height) + var(--height-flash-info) - 140px);
}
@media (--tablet) {
  .main--flash-info .page-article__heading {
    margin-top: 0;
  }
}

.page-article__heading {
  --height: 340px;

  position: relative;

  width: 100%;
  height: var(--height);
}
@media (--tablet) {
  .page-article__heading {
    --height: 300px;

    margin-left: calc(-1 * var(--responsive-margin));
    margin-right: calc(-1 * var(--responsive-margin));
  }

  .page-article__heading::before {
    background-color: rgba(255, 255, 255, 0.85);
    height: var(--height-flash-info);
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;

    content: '';
  }
}
@media (--mobile) {
  .page-article__heading {
    --height: 270px;
  }
}

.page-article__heading img {
  width: 100%;
  height: 100%;
  margin-top: var(--margin-top);
  object-fit: cover;

  border-radius: 8px;
}
@media (--tablet) {
  .page-article__heading img {
    width: 100vw;
    border-radius: 0;
  }
}
