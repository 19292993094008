.journey__360-show-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: black;
  user-select: none;
  pointer-events: none;
}
@media (--mobile) {
  .journey__360-show-background {
    bottom: auto;
    height: 400px;
    overflow: hidden;
  }
}

.journey__360-show-background__perspective {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: black;
  user-select: none;
  pointer-events: none;
  perspective: 800px;
}

.journey__360-show-background__transform {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotate3d(0, 1, 0, 25deg);
}
@media (--mobile) {
  .journey__360-show-background__transform {
    top: -32px;
    transform: rotate3d(0, 1, 0, -50deg);
  }
}

@keyframes journey-360-show-image {
  from {
    transform: translateX(-50%) translateX(-20%);
  }
  to {
    transform: translateX(-50%) translateX(20%);
  }
}
@keyframes journey-360-show-image-mobile {
  from {
    transform: translateX(-50%) translateX(-25%);
  }
  to {
    transform: translateX(-50%) translateX(25%);
  }
}

.journey__360-show-background__wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  height: 100%;
  transform: translateX(-50%);
  animation-name: journey-360-show-image;
  animation-duration: 50s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate-reverse;
  animation-iteration-count: infinite;
  will-change: transform;
}
@media (--mobile) {
  .journey__360-show-background__wrap {
    animation-name: journey-360-show-image-mobile;
    animation-duration: 30s;
  }
}

.journey__360-show-background__wrap::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, black 0%, rgba(0, 0, 0, 0) 15%),
    linear-gradient(to left, black 0%, rgba(0, 0, 0, 0) 15%);
}

.journey__360-show-background__image {
  width: auto;
  height: 100%;
}

.journey__360-show-background__gradient {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  background-image: linear-gradient(
    to left,
    var(--background-color) 32%,
    rgba(7, 21, 38, 0) 87%
  );
}
@media (--mobile) {
  .journey__360-show-background__gradient {
    background-image: linear-gradient(
        65deg,
        var(--background-color) 20%,
        rgba(7, 21, 38, 0) 40%
      ),
      linear-gradient(
        to top,
        var(--background-color) 30%,
        rgba(7, 21, 38, 0) 80%
      );
  }
}
